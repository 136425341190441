
//component
import { Row } from "antd";
import Filter from "./component/Filter";
import AdminListTable from "./component/Table";
import { useAuth } from "src/store/auth/selector";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "src/store";
import { fetchListAdmin } from "src/store/auth/action";
import DialogActive from "./dialog/dialog_active";
import DialogDelete from "./dialog/dialog_delete";
import DialogPayment from "./dialog/dialog_payment"

const AdminListManagement = () => {
    //================ VAR ====================
    //================ STATE ==================
    //================ REDUX ==================
    const dispatch = useAppDispatch()
    const dataAuth = useAuth()
    const { listAdmin, active } = dataAuth
    
    //================ FUNCTION ===============
    //================ EFFECT =================
    useEffect(() => {
        dispatch(fetchListAdmin())
    },[dispatch])
    const dataTable = useMemo(() => {
        switch (active) {
            case "active":
              return listAdmin?.filter((dt) => dt?.isActive)
            case "deactive":
              return listAdmin?.filter((dt) => !dt?.isActive)
            default:
              return listAdmin;
          }
    },[listAdmin, active])
    //================ REDNER =================
    //================ MAIN ===================
    return (
        <Row gutter={[0,24]} style={{background:"white", padding:"24px", borderRadius:"12px"}}>
            <Filter />
            <AdminListTable listAdmin={dataTable} />
            <DialogActive />
            <DialogDelete />
            <DialogPayment />
        </Row>
    )
}

export default AdminListManagement