import React from 'react'

const Icon = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.5 8.83984H14.9C15.79 8.83984 16.5 9.55982 16.5 10.4398V12.2098'
        stroke='#1A202C'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.19 7.15991L7.5 8.83997L9.19 10.53'
        stroke='#1A202C'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.5 16.16H9.10001C8.21001 16.16 7.5 15.4401 7.5 14.5601V12.79'
        stroke='#1A202C'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.8101 17.84L16.5001 16.16L14.8101 14.47'
        stroke='#1A202C'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z'
        stroke='#1A202C'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default Icon
