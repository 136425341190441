import { Col, Table } from "antd";
import { useAppDispatch } from "src/store";
import dayjs from "dayjs"
//components
//actions
import { setPagination } from "src/store/general/slice";

//selector
import { useGeneralSelector } from "src/store/general/selector";
import { formatNumer } from "src/utils/formatNumber";
//function
//str

const GeneralTableTable = () => {
  // -------------------------- VAR --------------------------
  const columns = [
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Tên evoucher",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      render:(value:string) => {
        return (
          <span>{dayjs(value).format("DD/MM/YYYY HH:mm:ss")}</span>
        )
      }
    },
    {
      title: "Giá (VNDC)",
      dataIndex: "price",
      key: "price",
      render:(_: any, record: any) => {
        return (
          <span>{formatNumer(record?.totalAmountPaid/record?.quantity)}</span>
        )
      }
    },
    {
      title: "Thành tiền (VNDC)",
      dataIndex: "totalAmountPaid",
      key: "totalAmountPaid",
      render:(value:number) => {
        return (
          <span>{formatNumer(value)}</span>
        )
      }
    }
  ]
  // -------------------------- STATE ------------------------
  // -------------------------- REDUX ------------------------
  const dispatch = useAppDispatch()
  const data = useGeneralSelector()
  // -------------------------- FUNCTION ---------------------
  const handleTableChange = (pagination: any) => {
    dispatch(setPagination(pagination))
  };
  // -------------------------- EFFECT -----------------------
  // -------------------------- RENDER -----------------------
  // -------------------------- MAIN -------------------------
  return (
      <Col span={24}>
          <Table
            dataSource={data?.orderAdminList?.orderAdminList?.orders}
            columns={columns}
            scroll={{ x: "max-content" }}
            pagination={{
              total: data?.orderAdminList?.orderAdminList?.total,
              pageSizeOptions: [10, 20, 30]
            }}
            onChange={handleTableChange}
          />
      </Col>
  );
};
export default GeneralTableTable;