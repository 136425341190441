import { Divider, Form, Input } from 'antd'
//components
//actions
//selector
//type

//function
//str

const FormCreateDescription = ({ form }: {form:any}) => {
  // -------------------------- VAR ----------------------------
  const description = Form.useWatch('description', form)

  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <>
      <Form.Item
        label='Điều kiện sử dụng'
        name='description'
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
      >
        <Input.TextArea
          placeholder="Nhập điều kiện sử dụng..."
          value={description}
          rows={4}
        />
      </Form.Item>
      <Divider />
    </>
  )
}
export default FormCreateDescription
