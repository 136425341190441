
//component

import FormCreate from "./component/FormCreate"
import Header from "./component/Header"
import { Container } from "./style"

const CreateVoucher = () => {
    return (
        <Container item>
            <Header/>
            <FormCreate />
        </Container>
    )
}

export default CreateVoucher