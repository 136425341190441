import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from './auth/slice'
import adminReducer from './admin/slice';
import voucherReducer from "./voucher/slice"
import generalReducer from "./general/slice"
import depositReducer from "./deposit/slice"

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    auth: authReducer,
    admin: adminReducer,
    voucher: voucherReducer,
    general: generalReducer,
    deposit: depositReducer
  },
});

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store

