import { Form, Row, Col, Button, InputNumber, notification, DatePicker } from 'antd'
import dayjs from "dayjs"
import { createUrboxDeposit } from 'src/store/deposit/fetchData'
import { ContainerDate, ContainerTitle } from '../style'
import { CalendarSearchIcon } from 'src/component/Svg'
import { useAppDispatch } from 'src/store'
import { fetchUrbox } from 'src/store/deposit/action'

const FormDeposit = () => {
  // ================== VAR ==================
  const [form] = Form.useForm()
  const date = Form.useWatch("depositDate", form)
  const amount = Form.useWatch("amount", form)
  // ================== STATE ==================
  // ================== REDUX ==================
  const dispatch = useAppDispatch()
  // ================== FUNCTION ================
  const onFinish = async (values: any) => {
    const date = new Date(values?.depositDate).toISOString()
    const res = await createUrboxDeposit(date, values?.amount)
    if (res?.status === 201) {
      notification.open({
        message: 'Nhập ký quỹ thành công',
        type: 'success'
      })
      form.resetFields()
      dispatch(fetchUrbox())
    } else {
      notification.open({
        message: 'Nhập ký quỹ thất bại',
        type: 'error'
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const currencyFormatter = (value: any) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const currencyParser = (value: any) => {
    return value.replace(/\$\s?|(,*)/g, '')
  }

  const handleChange = (dates:any) => {
    form.setFieldValue("depositDate", dates?.$d);
  };

  // ================== EFFECT ==================
  // ================== RENDER ==================
  // ================== MAIN ==================
  return (
    <Form
      size='large'
      autoComplete='off'
      form={form}
      id='form-create-deposit'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row justify='start' gutter={[24, 0]}>
        <Col xl={4} lg={4} md={6} xs={12}>
          <Form.Item name='depositDate'>
            <ContainerDate>
              <ContainerTitle>Ký ngày</ContainerTitle>
              <DatePicker
                format='DD/MM/YYYY'
                style={{ fontSize: 16 }}
                suffixIcon={<CalendarSearchIcon />}
                placeholder='Ngày ký quỹ'
                onChange={handleChange}
                value={dayjs(date)}
              />
            </ContainerDate>
          </Form.Item>
        </Col>
        <Col xl={8} lg={8} md={12} xs={12}>
          <Form.Item name='amount'>
            <InputNumber
              placeholder='Nhập số tiền đã ký quỹ'
              style={{ width: '100%' }}
              formatter={currencyFormatter}
              parser={currencyParser}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button
            size='large'
            type='primary'
            form='form-create-deposit'
            key='submit'
            htmlType='submit'
            disabled={!date || !amount}
          >
            Thêm
        </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default FormDeposit
