import { Divider, Form, Select } from 'antd'
//components
//actions
//selector
//type

//function
//str
import locations from '../../../../configs/dvhcvn.json'

const { Option } = Select
const FormCreateLocation = ({ form }: { form: any }) => {
  // -------------------------- VAR ----------------------------
  const location = Form.useWatch('location', form)
  // -------------------------- REDUX -------------------------
  // -------------------------- FUNCTION ---------------------
  const handleChange = (evt: string) => {
    form.setFieldValue({ location, evt })
  }
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <>
      <Form.Item
        label='Địa điểm'
        name='location'
        rules={[
          {
            required: true,
            message: 'Địa điểm không được rỗng'
          }
        ]}
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
      >
        <Select value={location} onChange={handleChange} placeholder='Chọn địa điểm'>
          {locations?.map(dt => {
            return <Option value={dt?.provinceId}>{dt?.provinceName}</Option>
          })}
        </Select>
      </Form.Item>
      <Divider />
    </>
  )
}
export default FormCreateLocation
