import { Typography } from 'antd'
import _ from 'lodash'
import { AdminInfoItem } from 'src/store/auth/type'

const { Text } = Typography

const Roles = ({ record }: { record: AdminInfoItem }) => {
  return (
    <>
      {record?.roles?.map(dt => {
        return <Text>{_.capitalize(dt)}</Text>
      })}
    </>
  )
}

export default Roles
