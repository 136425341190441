import { ReactNode, createContext } from 'react'
import { useAppDispatch } from 'src/store'
import { fetchLogin, fetchLogout } from 'src/store/auth/action'

interface LoginContextProps {
    login: (userName:string, passWord: string) => void
    logout: () => void
}

export const LoginContext = createContext<LoginContextProps>({
    login: () => null,
    logout: () => null,
})

export const LoginProvider = ({ children }:{children:ReactNode}) => {
    const dispatch = useAppDispatch()
    const login = (userName:string, passWord:string) => {
        dispatch(fetchLogin({ userName, passWord }))
    }
    const logout = () => {
        dispatch(fetchLogout())
    }
    return (
        <LoginContext.Provider value={{ login, logout }}>
            {children}
        </LoginContext.Provider>
    )
}
