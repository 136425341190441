import { Divider, Form, InputNumber } from 'antd'
import { CsBox } from '../../style'
//components
//actions
//selector
//type

//function
//str

const FormCreatePromote = ({ form }: { form: any }) => {
  // -------------------------- VAR ----------------------------
  const promote = Form.useWatch('promote', form)

  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  const currencyFormatter = (value: any) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const currencyParser = (value: any) => {
    return value.replace(/\$\s?|(,*)/g, '')
  }
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <>
      <Form.Item
        label='Giá khuyến mãi'
        name='promote'
        rules={[
          {
            required: true,
            message: 'Giá khuyến mãi không được rỗng'
          },
          {
            pattern: /^[0-9]+\.?([0-9]+)?$/,
            message: 'Giá khuyến mãi định không đúng dạng'
          }
        ]}
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
      >
        <InputNumber
          value={promote}
          size='large'
          placeholder='0'
          style={{ width: '100%' }}
          formatter={currencyFormatter}
          parser={currencyParser}
          suffix={<CsBox>đ</CsBox>}
        />
      </Form.Item>
      <Divider />
    </>
  )
}
export default FormCreatePromote
