import { Form, notification } from "antd";
import FormCreateAmount from "./component/form_create_amount";
import FormCreatePrice from "./component/form_create_price";
import FormCreatePromote from "./component/form_create_promote";
import FormCreateValue from "./component/form_create_value";
import FormCreateDescription from "./component/form_create_description";
import FormCreateAddress from "./component/form_create_address";
import { createVoucher } from "src/store/admin/fetchData";
import { useAuth } from "src/store/auth/selector";
import FormCreateExpireDate from "./component/form_create_expire_date";

const FormCreate = () => {
    // ================== VAR ==================
    const [form] = Form.useForm();
    const { useInfo } = useAuth()
    
    // ================== STATE ==================
    // ================== REDUX ==================
    // ================== FUNCTION ================
    const formatNumber = (value:number) => {
      if(!isNaN(value)) {
        return  Number(value).toLocaleString('en', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
      return "0"
    }
    const onFinish = async(values: any) => {
        const addresses = values?.address?.split("\n")
        const name = `[GiftCard Voucher] ${useInfo?.brandName} ${formatNumber(Number(values?.value))} đ`
        const expireDate = new Date(values?.expireDate).toISOString()
        const res = await createVoucher(name, values?.amount, values?.description, addresses, values?.price, values?.promote, values?.value, expireDate)
        if(res?.status === 201) {
          notification.open({
            message: 'Tạo voucher thành công',
            type: "success"
          });
          form.resetFields();
        } else {
          notification.open({
            message: 'Tạo voucher thất bại',
            type: "error"
          });
        }
    } 
    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    // ================== EFFECT ==================
    // ================== RENDER ==================
    // ================== MAIN ==================
    return (
        <Form
          size="small"
          autoComplete="off"
          form={form}
          id="form-create-voucher"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <FormCreateAmount form={form} />
          <FormCreateExpireDate form={form} />
          <FormCreatePrice form={form} />
          <FormCreatePromote form={form} />
          <FormCreateValue form={form} />
          <FormCreateDescription form={form} />
          <FormCreateAddress form={form} />
        </Form>
    )
}

export default FormCreate