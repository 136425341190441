import { Form, notification } from 'antd'
import axios from 'axios'
import { ROLE_PROVIDER } from 'src/configs'
import { API_URL } from 'src/configs/api/APP_URL'
import FormCreateBrandName from "./component/form_create_brand_name"
import FormCreateDescription from './component/form_create_description'
import FormCreateLogo from './component/form_create_logo'
import FormCreateName from './component/form_create_name'
import FormCreatePassword from './component/form_create_password'
import FormCreateRePassword from './component/form_create_re_password'
import FormCreateLocation from "./component/form_create_location"

const FormCreate = () => {
  // ================== VAR ==================
  const [form] = Form.useForm()
  // ================== STATE ==================
  // ================== REDUX ==================
  // ================== FUNCTION ================
  const onFinish = async(values: any) => {
    try {
      const formData = new FormData();
      formData.append('username', values.username);
      formData.append("brandName", values.brandName)
      formData.append('description', values.description)
      formData.append('logo', values.logo?.originFileObj);
      formData.append('password', values.password)
      formData.append('address', values?.location)
      formData.append('roles[0]', ROLE_PROVIDER)
      const response = await axios.post(API_URL.CREATE_ADMIN, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Server response:', response.data);
      notification.open({
        message: 'Tài khoản được tạo thành công',
        type: "success"
      });
      form.resetFields();
    } catch (error) {
      console.error('Error:', error);
      notification.open({
        message: 'Không tạo được tài khoản. Vui lòng thử lại.',
        type: "error"
      });
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  // ================== EFFECT ==================
  // ================== RENDER ==================
  // ================== MAIN ==================
  return (
    <Form
      size='large'
      autoComplete='off'
      form={form}
      id='form-create-admin'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <FormCreateLogo form={form} />
      <FormCreateName form={form} />
      <FormCreateBrandName form={form} />
      <FormCreateLocation form={form} />
      <FormCreatePassword form={form} />
      <FormCreateRePassword form={form} />
      <FormCreateDescription form={form} />
    </Form>
  )
}

export default FormCreate
