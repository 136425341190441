import { Button, Col, DatePicker, Form, InputNumber, Modal, Row, notification } from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { CalendarSearchIcon } from 'src/component/Svg'
import { useAppDispatch } from 'src/store'
import { fetchUrbox } from 'src/store/deposit/action'
import { editUrboxDeposit } from 'src/store/deposit/fetchData'
import { useDepositSelector } from 'src/store/deposit/selector'
import { setDialogEdit, setOpenDialog } from 'src/store/deposit/slice'
import { ContainerDate, ContainerTitle } from '../style'

const DialogEdit = () => {
  //================ VAR ====================
  const [form] = Form.useForm()
  const date = Form.useWatch('depositDate', form)
  const amount = Form.useWatch('amount', form)
  //================ STATE ==================
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  const { isOpenDialog, dialogEdit } = useDepositSelector()
  //================ FUNCTION ===============
  const handleCancel = () => {
    dispatch(setOpenDialog(false))
    dispatch(
      setDialogEdit({
        amount: 0,
        date: '',
        id: ''
      })
    )
  }

  const currencyFormatter = (value: any) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const currencyParser = (value: any) => {
    return value.replace(/\$\s?|(,*)/g, '')
  }

  const onFinish = async (values: any) => {
    const date = new Date(values?.depositDate).toISOString()
    const res = await editUrboxDeposit(date, values?.amount, dialogEdit?.id)
    if (res?.status === 200) {
      notification.open({
        message: 'Chỉnh sửa ký quỹ thành công',
        type: 'success'
      })
      form.resetFields()
      dispatch(fetchUrbox())
      handleCancel()
    } else {
      notification.open({
        message: 'Chỉnh sửa ký quỹ thất bại',
        type: 'error'
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const handleChange = (dates: any) => {
    form.setFieldValue('depositDate', dates?.$d)
  }
  //================ EFFECT =================
  useEffect(() => {
    form.setFieldValue('amount', dialogEdit?.amount)
    form.setFieldValue('depositDate', dayjs(dialogEdit?.date))
  }, [dialogEdit])
  //================ REDNER =================
  //================ MAIN ===================
  return (
    <Modal title='Thay đổi số tiền ký quỹ' open={isOpenDialog} onCancel={handleCancel} footer={null}>
      <Row justify='center' align='middle' gutter={[0, 24]} style={{ marginTop: 16 }}>
        <Col span='24'>
          <Form
            size='large'
            autoComplete='off'
            form={form}
            id='form-deposit-edit'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={24}>
                <Form.Item name='depositDate' initialValue={dayjs(dialogEdit?.date)}>
                  <ContainerDate>
                    <ContainerTitle>Ký ngày</ContainerTitle>
                    <DatePicker
                      format='DD/MM/YYYY'
                      style={{ fontSize: 16, width: '100%' }}
                      suffixIcon={<CalendarSearchIcon />}
                      placeholder='Ngày ký quỹ'
                      onChange={handleChange}
                      // value={date}
                    />
                  </ContainerDate>
                </Form.Item>
              </Col>
            </Row>
            <Row justify='start' gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item name='amount'>
                  <InputNumber
                    placeholder='Nhập số tiền đã ký quỹ'
                    style={{ width: '100%' }}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify='space-around' style={{ marginTop: '18px' }}>
        <Button style={{ width: '150px' }} onClick={handleCancel}>
          Huỷ
        </Button>
        <Button
          type='primary'
          style={{ width: '150px' }}
          form='form-deposit-edit'
          key='submit'
          htmlType='submit'
          disabled={!date || !amount}
        >
          Xác nhận
        </Button>
      </Row>
    </Modal>
  )
}

export default DialogEdit
