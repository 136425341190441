import { Table, Col } from 'antd'
import dayjs from "dayjs"
import { VoucherItem } from 'src/store/admin/type'
import VouchertableAmount from './component/VoucherTableAmount'
import VoucherTableDescription from './component/VoucherTableDescription'
import VouchertableAddresses from './component/VoucherTableAddresses'
import VouchertableDetele from './component/VoucherTableDelete'

const VoucherTable = ({ listVoucher, isLoading }: { listVoucher: VoucherItem[]; isLoading: boolean }) => {
  const columns = [
    {
      title: 'Tên voucher',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Số lượng',
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: VoucherItem) => {
        return <VouchertableAmount value={record?.amount} />
      }
    },
    {
      title: 'Số lượng còn lại',
      dataIndex: 'remainQuantity',
      key: 'remainQuantity',
      render: (_: any, record: VoucherItem) => {
        return <VouchertableAmount value={record?.remainQuantity} />
      }
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      render: (_: any, record: VoucherItem) => {
        return <VouchertableAmount value={record?.price} isShowUnit />
      }
    },
    {
      title: 'Giá khuyến mãi',
      dataIndex: 'promoPrice',
      key: 'promoPrice',
      render: (_: any, record: VoucherItem) => {
        return <VouchertableAmount value={record?.promoPrice} isShowUnit />
      }
    },
    {
      title: 'Giá trị',
      dataIndex: 'value',
      key: 'value',
      render: (_: any, record: VoucherItem) => {
        return <VouchertableAmount value={record?.value} isShowUnit />
      }
    }, 
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (_: any, record: VoucherItem) => {
        return <span>{dayjs(record?.expireDate).format("DD-MM-YYYY")}</span>
      }
    },
    {
      title: 'Điều kiện sử dụng',
      dataIndex: 'description',
      width: 250,
      render: (_: any, record: VoucherItem) => {
        return <VoucherTableDescription description={record?.description} />
      }
    },
    {
      title: 'Địa điểm áp dụng',
      dataIndex: 'addresses',
      key: 'addresses',
      render: (_: any, record: VoucherItem) => {
        return <VouchertableAddresses addresses={record?.addresses} />
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align:"center" as const,
      fixed:"right" as const,
      render: (_: any, record: VoucherItem) => {
        return <VouchertableDetele record={record}  />
      },
      width: 150
    },
  ]

  return <Table dataSource={listVoucher} columns={columns} scroll={{ x: 'max-content' }} loading={isLoading} />
}

export default VoucherTable
