import { Layout } from "antd";
//component
import Filter from "./component/Filter";
import Table from "./component/Table";
import DialogConfirm from "./dialog/dialog_confirm";

const { Content } = Layout
const Voucher = () => {
    return (
        <Content style={{background:"white", padding:"24px", borderRadius:"12px"}}>
            <Filter />
            <Table />
            <DialogConfirm />
        </Content>
    )
}

export default Voucher