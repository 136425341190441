import { Divider, Form, Input } from 'antd'
import { useState } from 'react'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import EyeOutline from 'mdi-material-ui/EyeOutline'
//components
//actions
//selector
//type

//function
//str

const FormCreateRePassword = ({ form }: { form: any }) => {
  // -------------------------- VAR ----------------------------
  const rePassword = Form.useWatch('rePassword', form)
  // -------------------------- STATE -------------------------
  const [isShowPass, setIsShowPass] = useState(false)

  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  const validateRePassword = () => ({
    validator(_: any, value: any) {
      if (!value || form.getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Nhập lại mật khẩu không đúng!'));
    },
  });

  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <>
      <Form.Item
        label='Nhập lại mật khẩu'
        name='rePassword'
        rules={[
          {
            required: true,
            message: 'Mật khẩu không được rỗng'
          },
          validateRePassword
        ]}
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
      >
        <Input
          value={rePassword}
          size='large'
          placeholder='Nhập lại mật khẩu'
          type={!isShowPass ? "password" : "text"}
          suffix={
            !isShowPass ? (
              <EyeOutline onClick={() => setIsShowPass(!isShowPass)} />
            ) : (
              <EyeOffOutline onClick={() => setIsShowPass(!isShowPass)} />
            )
          }
        />
      </Form.Item>
    </>
  )
}
export default FormCreateRePassword
