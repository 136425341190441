import { DatePicker, Divider, Form, Row } from 'antd'
import dayjs from "dayjs"
//components
//actions
//selector
//type

//function
//str

const FormCreateExpireDate = ({ form }: { form: any }) => {
  // -------------------------- VAR --------------------------
  const expireDate = Form.useWatch('expireDate', form)
  // -------------------------- REDUX ------------------------
  // -------------------------- FUNCTION ---------------------
  const handleChange = (dates:any) => {
    form.setFieldValue("expireDate", dates?.$d);
  };
  // -------------------------- EFFECT -----------------------
  // -------------------------- RENDER -----------------------
  // -------------------------- MAIN -------------------------
  return (
    <>
      <Form.Item
        label='Ngày hết hạn'
        name='expireDate'
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
        initialValue={dayjs().add(1, "day")}
      >
        <Row>
          <DatePicker
            style={{width:"100%"}}
            showTime
            format="DD-MM-YYYY HH:mm:ss"
            disabledDate={(currentDate) => currentDate < expireDate}
            allowClear={false}
            size='large'
            placeholder='Chọn ngày hết hạn'
            defaultValue={dayjs().add(1, "day")}
            onChange={handleChange}
          />
        </Row>
      </Form.Item>
      <Divider />
    </>
  )
}
export default FormCreateExpireDate
