import { Typography } from "antd";
import { AdminInfoItem } from "src/store/auth/type";


const { Text } = Typography

const Description = ({record}: {record:AdminInfoItem}) => {
    if(record?.description !== "undefined") {
        return (
            <Text style={{width:"100%", wordBreak:"break-word"}}>
                {record?.description}
            </Text>
        )
    }
    return (
        <Text>---</Text>
    )
}

export default Description