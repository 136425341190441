import { Button, Col, InputNumber, Modal, Row, Space, Typography, notification } from 'antd'
import { useMemo, useState } from 'react'
import { useAppDispatch } from 'src/store'
import { fetchListAdmin } from 'src/store/auth/action'
import { getPaymenet } from 'src/store/auth/fetchData'
import { useAuth } from 'src/store/auth/selector'
import { setDialogPayment, setOpenDialogPayment } from 'src/store/auth/slice'
import { CsBox } from 'src/views/CreateVoucher/style'

const { Text } = Typography

const DialogPayment = () => {
  //================ VAR ====================
  //================ STATE ==================
  const [amount, setAmount] = useState(0)
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  const dataAuth = useAuth()
  const { isOpenDialogPayment, dialogPayment } = dataAuth
  //================ FUNCTION ===============
  const currencyFormatter = (value: any) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const currencyParser = (value: any) => {
    return value.replace(/\$\s?|(,*)/g, '')
  }
  const formatNumber = (value: string | number) => {
    if (value) {
      return `${Number(value).toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}đ`
    }
    return '0'
  }
  const handleUpdateData = () => {
    dispatch(fetchListAdmin())
  }
  const handleCancel = () => {
    dispatch(setOpenDialogPayment(false))
    dispatch(
      setDialogPayment({
        username: '',
        maxAmount: 0,
        id: ''
      })
    )
  }
  const handleConfirm = async () => {
    const res = await getPaymenet(dialogPayment?.id, amount)
    if (res?.status === 201) {
      notification.open({
        message: `Thanh toán cho đối tác ${dialogPayment?.username} thành công.`,
        type: 'success'
      })
      handleCancel()
      handleUpdateData()
      setAmount(0)
    } else {
      notification.open({
        message: `Thanh toán cho đối tác ${dialogPayment?.username} không thành công.`,
        type: 'error'
      })
    }
  }
  //================ EFFECT =================
  const isErr = useMemo(() => {
    if (Number(amount) <= 0) {
      return {
        isDisable: !false,
        text: 'Số tiền thanh toán phải lớn hơn 0'
      }
    }
    if (Number(amount) > dialogPayment?.maxPayment && Number(amount) > 0) {
      return {
        isDisable: !false,
        text: `Số tiền thanh toán phải nhỏ hơn ${formatNumber(dialogPayment?.maxPayment)}`
      }
    }
    return {
      isDisable: false,
      text: ''
    }
  }, [amount, dialogPayment?.maxPayment])

  //================ REDNER =================
  //================ MAIN ===================
  return (
    <Modal title={`Thanh toán cho đối tác ${dialogPayment?.username}`} open={isOpenDialogPayment} onCancel={handleCancel} footer={null}>
      <Row justify='center' align='middle' gutter={[0, 24]}>
        <Col span='24'>
          <Row justify='start' align='middle'>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Text>Nhập số tiền cần thanh toán</Text>
              <InputNumber
                value={amount}
                size='large'
                placeholder='0'
                style={{ width: '100%' }}
                formatter={currencyFormatter}
                parser={currencyParser}
                suffix={<CsBox>đ</CsBox>}
                onChange={(evt: any) => setAmount(evt)}
                min={0}
              />
              {isErr?.isDisable && (
                <Text type='danger' style={{ fontSize: '11px' }}>
                  {isErr?.text}
                </Text>
              )}
            </Space>
          </Row>
        </Col>
      </Row>
      <Row justify='space-around' style={{ marginTop: '18px' }}>
        <Button style={{ width: '150px' }} onClick={handleCancel}>
          Huỷ
        </Button>
        <Button type='primary' style={{ width: '150px' }} disabled={isErr?.isDisable} onClick={handleConfirm}>
          Xác nhận
        </Button>
      </Row>
    </Modal>
  )
}

export default DialogPayment
