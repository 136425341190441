import axios from "axios";
import { API_URL } from "./APP_URL";

const createVoucher = async (data: {
    name: string,
    amount: number,
    description: string,
    addresses: string[],
    price: number,
    promoPrice: string,
    value: string,
    expireDate: string
}) => (await axios.post(API_URL.CREATE_VOUCHER, data)).data

const allVoucer = async () => (await axios.get(API_URL.ALL_VOUCHER)).data
const statictis = async () => (await axios.get(API_URL.STATICTIS)).data

const adminList = async (data: {
    skip: number,
    limit: number,
    fromDate: string,
    toDate: string,
    search: string
}) => (await axios.get(API_URL.ORDER_ADMIN_LIST, {params:data})).data

export const adminApi = {
    createVoucher, allVoucer, adminList, statictis
}

