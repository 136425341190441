import { Modal, Avatar, Row, Typography, Col, Button, notification } from 'antd'
import { useAppDispatch } from 'src/store'
import { fetchListAdmin } from 'src/store/auth/action'
import { getUpdateStatus } from 'src/store/auth/fetchData'
import { useAuth } from 'src/store/auth/selector'
import { setDialogActive, setOpenDialogActive } from 'src/store/auth/slice'

const { Text } = Typography

const DialogActive = () => {
  //================ VAR ====================
  //================ STATE ==================
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  const dataAuth = useAuth()
  const { isOpenDialogActive, dialogActive } = dataAuth
  //================ FUNCTION ===============
  const handleUpdateData = () => {
    dispatch(fetchListAdmin())
  }
  const handleCancel = () => {
    dispatch(setOpenDialogActive(false))
    dispatch(
      setDialogActive({
        type: null,
        username: '',
        logo: ''
      })
    )
  }
  const handleConfirm = async() => {
    if (dialogActive.type === 'active') {
      const res = await getUpdateStatus(dialogActive?.username, !false)
      if(res?.status === 201) {
        notification.open({
          message: `Kích hoạt tài khoản ${dialogActive?.username} thành công.`,
          type: "success"
        });
        handleCancel()
        handleUpdateData()
      } else {
        notification.open({
          message: `Kích hoạt tài khoản ${dialogActive?.username} không thành công.`,
          type: "error"
        });
      }
    } else {
      const res = await getUpdateStatus(dialogActive?.username, false)
      if(res?.status === 201) {
        notification.open({
          message: `Ngưng kích hoạt tài khoản ${dialogActive?.username} thành công.`,
          type: "success"
        });
        handleCancel()
        handleUpdateData()
      } else {
        notification.open({
          message: `Ngưng kích hoạt tài khoản ${dialogActive?.username} không thành công.`,
          type: "error"
        });
      }
    }
  }
  //================ EFFECT =================
  //================ REDNER =================
  const renderTitle = () => {
    if (dialogActive.type === 'active') {
      return 'Kích hoạt tài khoản'
    }
    return 'Ngưng kích hoạt tài khoản'
  }
  //================ MAIN ===================
  return (
    <Modal title={renderTitle()} open={isOpenDialogActive} onCancel={handleCancel} footer={null}>
      <Row justify='center' align='middle' gutter={[0, 24]}>
        <Col span='24'>
          <Row justify='center' align='middle'>
            <Avatar size={72} src={dialogActive?.logo} />
          </Row>
        </Col>
        <Col span={24}>
          <Row justify='center' align='middle'>
            { dialogActive.type === 'active' ?
              <Text style={{fontSize:"16px"}}>Bạn có chắc chắn muốn kích hoạt <span style={{fontWeight:"bold"}}>{dialogActive?.username}</span> ?</Text>
            :
              <Text style={{fontSize:"16px"}}>Bạn có chắc chắn muốn ngưng kích hoạt <span style={{fontWeight:"bold"}}>{dialogActive?.username}</span> ?</Text>
            }
          </Row>
        </Col>
      </Row>
      <Row justify="space-around" style={{marginTop:"18px"}}>
          <Button style={{width:"150px"}} onClick={handleCancel}>Huỷ</Button>
          <Button type='primary' style={{width:"150px"}} onClick={handleConfirm}>Xác nhận</Button>
      </Row>
    </Modal>
  )
}

export default DialogActive
