import dayjs from 'dayjs';
import { createSlice } from '@reduxjs/toolkit';
import { InitialStateType } from './type';
import { fetchOrderAdminList, fetchStatictis, fetchTotalOrderByDate } from './action';

const initialState: InitialStateType = {
    startDate: dayjs().subtract(7,"day"),
    toDate: dayjs(),
    search: "",
    pagination: {
        current: 1, 
        pageSize: 10
    },
    isLoading: false,
    orderAdminList: {
        total: 0,
        orders: []
    },
    statictis: {
        totalAmount: 0,
        totalQuantity: 0,
        deposit: 0
    },
    totalOrder: 0
}
export const generalSlice = createSlice({
    name: "generalSlice",
    initialState,
    reducers: {
        setStartDate: (state, action) => {
            const startDate = action.payload;
            state.startDate = startDate;
        },
        setToDate: (state, action) => {
            const toDate = action.payload;
            state.toDate = toDate;
        },
        setSearch: (state, action) => {
            const search = action.payload;
            state.search = search;
        },
        setPagination: (state, action) => {
            const pagination = action.payload;
            state.pagination = pagination;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchOrderAdminList.pending, (state) => {
            state.isLoading = !false
        })
        builder.addCase(fetchOrderAdminList.fulfilled, (state, action) => {
            const orderAdminList = action.payload
            state.orderAdminList = orderAdminList
            state.isLoading = false
        })
        builder.addCase(fetchOrderAdminList.rejected, (state) => {
            state.isLoading = false
        })
        builder.addCase(fetchStatictis.fulfilled, (state, action) => {
            const data = action.payload
            state.statictis = data?.statictis
        })
        builder.addCase(fetchTotalOrderByDate.fulfilled, (state, action) => {
            const data = action.payload
            state.totalOrder = data?.totalOrder
        })
    },
});
const { actions, reducer } = generalSlice;

export const { setStartDate, setToDate, setSearch, setPagination } = actions;

export default generalSlice.reducer 
