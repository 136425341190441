import { KEY_LOGIN, ROLE_PROVIDER } from "src/configs";
import { authApi } from "src/configs/api/auth";
import { AdminInfoItem, AuthType, ListAdminType, UserInfoType } from "./type";


export const getLogin = async (username: string, password: string): Promise<AuthType> => {
    try {
        const res = await authApi.auth({ username, password })
        localStorage?.setItem(KEY_LOGIN, res?.data?.accessToken)
        return {
            accessToken: res?.data?.accessToken,
            status: res?.status
        }
    } catch (error) {
        console.log(error)
        return {
            accessToken: null,
            status: 201
        }
    }
}

export const getLogout = async () => {
    try {
        await authApi.logout()
        localStorage?.removeItem(KEY_LOGIN)
    } catch (error) {
        console.log(error)
        return {
            accessToken: null
        }
    }
}


export const getUserInfo = async (): Promise<UserInfoType> => {
    try {
        const res = await authApi.userInfo()
        const findAdminRole = res?.data?.roles?.find((dt: string) => dt === "admin")
        return {
            useInfo: {
                username: res?.data?.username,
                roles: res?.data?.roles,
                isAdmin: findAdminRole ? !false : false,
                logo: res?.data?.logo,
                brandName: res?.data?.brandName,
            }
        }
    } catch (error) {
        console.log(error)
        return {
            useInfo: {
                username: "",
                roles: [],
                isAdmin: false,
                logo: "",
                brandName: ""
            }
        }
    }
}


export const getAdminList = async ():Promise<ListAdminType> => {
    try {
        const res = await authApi.adminList()
        const filterdData = res?.data?.filter((dt:AdminInfoItem) => {
            const roloes = dt?.roles
            return roloes.includes(ROLE_PROVIDER)
        })
        const converData = filterdData?.map((dt:any) => {
            return {
                username: dt?.username,
                logo: dt?.logo,
                isActive: dt?.isActive,
                description: dt?.description,
                roles: dt?.roles,
                id: dt?._id,
                finalizationPaid: dt?.finalizationPaid,
                finalizationAmount: dt?.finalizationAmount
            }
        })
        return {
            listAdmin: converData
        }
    } catch (error) {
        console.log(error)
        return {
            listAdmin: []
        }
    }
}

export const getUpdateStatus = async (username: string, isActive: boolean) => {
    try {
        const res = await authApi.update({ username, isActive })
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}

export const getDeleteAccount = async (id:string) => {
    try {
        const res = await authApi.deleteAccount({ id })
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}

export const getPaymenet = async (id:string, amount: number) => {
    try {
        const res = await authApi.paymnets({ id, amount })
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}
