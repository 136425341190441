import { createSlice } from '@reduxjs/toolkit';
import { InitialStateAdminType } from './type';
import { fetchAllVoucher } from './action';


const initialState: InitialStateAdminType = {
    listVoucher:[],
    isLoading: false,
    selectPrice: "all"
}
export const adminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        setSelectPrice: (state, action) => {
            const selectPrice = action.payload;
            state.selectPrice = selectPrice;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchAllVoucher.pending, (state) => {
            state.isLoading = !false
        })
        builder.addCase(fetchAllVoucher.fulfilled, (state, action) => {
            const data = action.payload
            state.listVoucher = data?.listVoucher
            state.isLoading = false
        })
        builder.addCase(fetchAllVoucher.rejected, (state) => {
            state.isLoading = false
        })
    },
});
const { actions, reducer } = adminSlice;

export const { setSelectPrice } = actions;

export default adminSlice.reducer 
