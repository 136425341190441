import { Divider, Form, Input } from 'antd'
//components
//actions
//selector
//type

//function
//str

const FormCreateName = ({ form }: { form: any }) => {
  // -------------------------- VAR ----------------------------
  const username = Form.useWatch('username', form)

  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  const validateNoWhitespace = (_: any, value: string) => {
    if (value.includes(" ")) {
      return Promise.reject('Tên đăng nhập không được có khoảng trắng');
    }
    return Promise.resolve();
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <>
      <Divider />
      <Form.Item
        label='Tên đăng nhập'
        name='username'
        rules={[
          {
            required: true,
            message: 'Tên đăng nhập không được rỗng'
          },
          { validator: validateNoWhitespace },
        ]}
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
      >
        <Input value={username} size='large' placeholder='Nhập tên đăng nhập' />
      </Form.Item>
      <Divider />
    </>
  )
}
export default FormCreateName
