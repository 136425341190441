import axios from "axios";
import { API_URL } from "./APP_URL";

const urboxDeposit = async () => (await axios.get(API_URL.URBOX_DEPOSIT)).data
const createUrboxDeposit = async (data: {date: string, amount: number}) => (await axios.post(API_URL.ADD_DEPOSIT, data)).data
const editUrboxDeposit = async (data: {date: string, amount: number, id:string}) => (await axios.patch(`${API_URL.ADD_DEPOSIT}/${data?.id}`,{amount: data?.amount, date: data?.date})).data

export const urboxApi = {
    urboxDeposit, createUrboxDeposit, editUrboxDeposit
}

