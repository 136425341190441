import axios from "axios";
import { API_URL } from "./APP_URL";

const auth = async (data: {
    username: string,
    password: string,
}) => (await axios.post(API_URL.LOGIN, data)).data

const logout = async () => (await axios.post(API_URL.LOGOUT)).data
const userInfo = async () => (await axios.get(API_URL.USER_INFO)).data

const adminList = async () => (await axios.get(API_URL.ADMIN_LIST)).data

const update = async (data: {
    username: string,
    isActive: boolean,
}) => (await axios.post(API_URL.UPDATE_STATUS, data)).data

const deleteAccount = async (params:{id:string}) => (await axios.delete(`${API_URL.DELETE}${params?.id}`)).data
const paymnets = async (data:{id:string, amount:number}) => (await axios.post(API_URL.PAYMENT, data)).data

export const authApi = {
    auth, logout, userInfo, adminList, update, deleteAccount, paymnets
}

