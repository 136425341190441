import { Button, Space } from 'antd'
import React from 'react'
import { useAppDispatch } from 'src/store'
import { setDialogConfirm, setOpenDialogConfirm } from 'src/store/voucher/slice'

const TableAction = ({ record }: { record: any }) => {
  //===================== VAR =========================
  //===================== STATE =======================
  //===================== REDUX =======================
  const dispatch = useAppDispatch()
  //===================== FUNCTION ====================
  const handleClick = () => {
    dispatch(setOpenDialogConfirm(!false))
    dispatch(
        setDialogConfirm({
            code: record?.code,
            price: record?.price,
      })
    )
  }
  //===================== EFFECT ======================
  //===================== REDNER ======================
  const renderActions = () => {
    switch (record?.status) {
      case 'sold':
        return <Button type='primary' onClick={handleClick}>Kích hoạt</Button>
      case 'used':
        return <Button disabled>Đã sử dụng</Button>
      default:
        return <Button disabled>Kích hoạt</Button>
    }
  }
  return <Space>{renderActions()}</Space>
}

export default TableAction
