import axios from "axios";
import { API_URL } from "./APP_URL";

const voucher = async (data:{code:string}) => (await axios.get(`${API_URL.VOUCHER}${data?.code}`)).data

const updateVoucher = async (data:{code:string}) => (await axios.post(API_URL.UPDATE_VOUCHER, data)).data

const deleteVoucher = async (data:{id:string}) => (await axios.delete(`${API_URL.DELETE_VOUCHER}${data?.id}`)).data

export const voucherApi = {
    voucher, updateVoucher, deleteVoucher
}

