import { Button, Col, Input, Row } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'src/store'
import { fetchVoucher } from 'src/store/voucher/action'

const Filter = () => {
  //================ VAR ==============================
  //================ STATE ============================
  const [ search, setSearch ] = useState("")
  //================ REDUX ============================
  const dispatch = useAppDispatch()
  //================ FUNCTION =========================
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt?.target?.value)
  }
  const handleClick = useCallback(() => {
    dispatch(fetchVoucher({code:search}))
  },[search])
  //================ EFFECT ===========================
  
  //================ REDNER ===========================
  //================ MAIN =============================
  return (
    <Row gutter={[16,0]}>
      <Col xl={6} lg={6} md={6} xs={12}>
        <Input value={search} placeholder='Nhập mã voucher' onChange={handleChange} size='large' />
      </Col>
      <Col>
        <Button type='primary' size='large' onClick={handleClick}>
           Tìm kiếm
        </Button>
      </Col>
    </Row>
  )
}

export default Filter
