import { Typography, Row, DatePicker, Col, Input, Grid } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import dayjs from "dayjs"
import type { GetProps } from 'antd';
import { CalendarSearchIcon } from "src/component/Svg";
import { useAppDispatch } from "src/store";
import { useGeneralSelector } from "src/store/general/selector";
import { setPagination, setSearch, setStartDate, setToDate } from "src/store/general/slice";
import { ContainerDate, ContainerTitle } from "../../style";
//components
//actions
//selector
//function
//str

const { Title } = Typography
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
const { useBreakpoint } = Grid

const GeneralTableHeader = () => {
  // -------------------------- VAR --------------------------
  const dateFormat = 'DD/MM/YYYY';
  const screens = useBreakpoint()
  // -------------------------- STATE ------------------------
  // -------------------------- REDUX ------------------------
  const dispatch = useAppDispatch()
  const { startDate, toDate, search } = useGeneralSelector()
  // -------------------------- FUNCTION ---------------------
  const onChangeStart = (date:any) => {
    dispatch(setStartDate(date))
  };
  const onChangeTo = (date:any) => {
    dispatch(setToDate(date))
  };
  const onSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setSearch(e?.target?.value))
    dispatch(setPagination({
        current: 1, 
        pageSize: 10
    }))
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const disabledToDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs(startDate).endOf('day');
  };
  const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs(toDate).endOf('day');
  };
  // -------------------------- EFFECT -----------------------
  // -------------------------- RENDER -----------------------
  // -------------------------- MAIN -------------------------
  return (
      <Col span={24}>
          <Row justify="space-between" align="middle" gutter={[0,16]}>
              <Col xl={8} lg={8} md={24} xs={24}>
                <Title level={2} style={{margin:0}}>Lịch sử giao dịch</Title>
              </Col>
              <Col xl={16} lg={16} md={24} xs={24}>
                  <Row gutter={[16,16]}>
                    <Col flex="auto">
                      <Input
                          placeholder="Tìm kiếm"
                          prefix={<SearchOutlined style={{fontSize:24}} />}
                          style={{height:48, borderRadius:8}}
                          onChange={onSearch}
                          value={search}
                      />
                    </Col>
                    <Col flex={screens?.xs ? "100%" : "156px"}>
                      <ContainerDate>
                        <ContainerTitle>Từ ngày</ContainerTitle>
                        <DatePicker disabledDate={disabledStartDate} onChange={onChangeStart} format={dateFormat} value={startDate} style={{height:48, fontSize:16}} suffixIcon={<CalendarSearchIcon />} />
                      </ContainerDate>
                    </Col>
                    <Col flex={screens?.xs ? "100%" : "156px"}>
                      <ContainerTitle>Đến ngày</ContainerTitle>
                      <DatePicker disabledDate={disabledToDate} onChange={onChangeTo} format={dateFormat} value={toDate} style={{height:48, fontSize:16}} suffixIcon={<CalendarSearchIcon />} />
                    </Col>
                  </Row>
              </Col>
          </Row>
      </Col>
  );
};
export default GeneralTableHeader;