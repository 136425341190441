import { adminApi } from "src/configs/api/admin";
import { ListVoucher } from "./type";


export const createVoucher = async (name: string, amount: number, description: string, addresses: string[], price: number, promoPrice: string, value: string, expireDate: string) => {
    try {
        const res = await adminApi.createVoucher({ name, amount, description, addresses, price, promoPrice, value, expireDate })
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}


export const getAllVoucher = async ():Promise<ListVoucher> => {
    try {
        const res = await adminApi.allVoucer()
        return {
            listVoucher: res?.data
        }
    } catch (error) {
        console.log(error)
        return {
            listVoucher: [] 
        }
    }
}