import React from 'react'
import { Typography, Row, Space } from 'antd'

const { Text } = Typography

const VouchertableAddresses = ({ addresses }: { addresses: string[] }) => {
  return (
    <Space direction='vertical'>
      {addresses?.map(dt => {
        return <Text>{dt}</Text>
      })}
    </Space>
  )
}

export default VouchertableAddresses
