import { Box, Button, Grid, GridProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Grid)<GridProps>(({ theme }) => ({
    background: theme.palette.background.paper,
    padding: theme.spacing(6,6),
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    minHeight: '100vh'
})) 

export const CsButtonOutline = styled(Button)(({ theme }) => ({
    border: "1px solid #DFE1E6",
    color: "#091228",
    background: "#fff"
}))

export const CsBox = styled(Box)(() => ({
    background: "#000",
    height:"32px",
    width:"32px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color: "white",
    textTransform:"none",
    borderRadius: "6px"
}))