import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { Provider } from 'react-redux'

import { SettingsConsumer, SettingsProvider } from './@core/context/settingsContext'
import ThemeComponent from './@core/theme/ThemeComponent'
import { LoginProvider } from './contexts/login'
import LoginLayout from './layouts/LoginLayout'
import UserLayout from './layouts/UserLayout'
import store from './store/index'
import { theme } from './theme'
import configAxios from './configs/api/config_axios'
import { RefreshContextProvider } from './contexts/RefreshContext'

const Providers = ({ children }: { children: React.ReactNode }) => {
  configAxios()
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LoginProvider>
          <LoginLayout>
            <SettingsProvider>
              <RefreshContextProvider>
                <SettingsConsumer>
                  {({ settings }) => {
                    return (
                      <ThemeComponent settings={settings}>
                        <UserLayout>{children}</UserLayout>
                      </ThemeComponent>
                    )
                  }}
                </SettingsConsumer>
              </RefreshContextProvider>
            </SettingsProvider>
          </LoginLayout>
        </LoginProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default Providers
