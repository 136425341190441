// ** React Imports
import { ReactNode, useContext, useEffect, useState } from 'react'

// ** MUI Components
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

// ** Icons Imports
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import EyeOutline from 'mdi-material-ui/EyeOutline'

// ** Configs

// ** Layout Import
import { Grid } from '@mui/material'
import { Form, Input, Button, notification } from 'antd'
import BlankLayout from 'src/@core/layouts/BlankLayout'
import { LogoIcon } from 'src/component/Svg'
import { LoginContext } from 'src/contexts/login'
import { useAuth } from 'src/store/auth/selector'
import { useAppDispatch } from 'src/store'
import { fetchDisableLoginFail } from 'src/store/auth/action'
import { KEY_LOGIN } from 'src/configs'

// ** Demo Imports
// import FooterIllustrationsV1 from 'src/views/pages/auth/FooterIllustration'

interface State {
  password: string
  showPassword: boolean
}

// ** Styled Components

const LoginPage = () => {
  // ** State
  const [api] = notification.useNotification();
  const dispatch = useAppDispatch()
  // ** Hook

  const { login } = useContext(LoginContext)
  const [form] = Form.useForm()
  const userName = Form.useWatch('userName', form)
  const password = Form.useWatch('password', form)
  const [isShowPass, setIsShowPass] = useState(false)
  const dataAuth = useAuth()
  const { isLoginFail } = dataAuth
  const onFinish = (values: any) => {
    login(values?.userName, values?.password)
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  useEffect(() => {
    if(isLoginFail) {
      notification.open({
        message: 'Tên dăng nhập hoặc mật khẩu không đúng',
        type: "error"
      });
      localStorage.removeItem(KEY_LOGIN)
      const timeoutId = setTimeout(() => {
          dispatch(fetchDisableLoginFail())  
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  },[isLoginFail])

  return (
    <Grid container xl={24}>
      <Grid item xl={6} lg={6} md={6} xs={12}>
        <Background />
      </Grid>
      <Grid item xl={6} lg={6} md={6} xs={12}>
        <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
          <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
            <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LogoIcon />
            </Box>
            <Box sx={{ mb: 6 }}>
              <Typography variant='h5' sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Welcome to GIFTCARDS
              </Typography>
              <Typography variant='body2'>Please sign-in to your account and start the adventure</Typography>
            </Box>
            <Form
              size='large'
              autoComplete='off'
              form={form}
              id='form-login'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name='userName'
                rules={[
                  {
                    required: true,
                    message: 'Tên đăng nhập không được rỗng'
                  }
                ]}
                wrapperCol={{ span: 24 }}
                labelAlign='left'
              >
                <Input value={userName} size='large' placeholder='Tên đăng nhập' />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Mật khẩu không được rỗng'
                  }
                ]}
                wrapperCol={{ span: 24 }}
                labelAlign='left'
              >
                <Input
                  value={password}
                  size='large'
                  type={!isShowPass ? "password" : "text"}
                  placeholder='Mật khẩu'
                  suffix={
                    !isShowPass ? (
                      <EyeOutline onClick={() => setIsShowPass(!isShowPass)} />
                    ) : (
                      <EyeOffOutline onClick={() => setIsShowPass(!isShowPass)} />
                    )
                  }
                  
                />
              </Form.Item>
              <Button
                  size='large'
                  style={{background: "linear-gradient(98deg, #FFC120 7.64%, #F78E01 92.53%), linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.00) 100%)", color:"white", width:"100%"}}
                  form='form-login'
                  key='submit'
                  htmlType='submit'
                >
                  Đăng nhập
                </Button>
            </Form>
          </CardContent>
        </Box>
      </Grid>
    </Grid>
  )
}

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default LoginPage

const Background = styled(Box)(() => ({
  width: '100%',
  height: '100vh',
  background: 'url(/images/pages/bg_login.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}))
