import { Button, Row, Space } from 'antd'
import React from 'react'
import { useAppDispatch } from 'src/store'
import { setOpenDialogActive, setDialogActive, setOpenDialogDelete, setDialogDelete, setDialogPayment, setOpenDialogPayment } from 'src/store/auth/slice'
import { AdminInfoItem } from 'src/store/auth/type'

const Action = ({ record }: { record: AdminInfoItem }) => {
  //================ VAR ====================
  //================ STATE ==================
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  //================ FUNCTION ===============
  const handleClickActive = () => {
    dispatch(setOpenDialogActive(!false))
    if (record?.isActive) {
      dispatch(
        setDialogActive({
          type: 'deactive',
          username: record?.username,
          logo: record?.logo
        })
      )
    } else {
      dispatch(
        setDialogActive({
          type: 'active',
          username: record?.username,
          logo: record?.logo
        })
      )
    }
  }
  
  const handleClickDelete = () => {
    dispatch(setOpenDialogDelete(!false))
    dispatch(
      setDialogDelete({
        username: record?.username,
        logo: record?.logo,
        id: record?.id
      })
    )
  }
  const handleClickPayment = () => {
    dispatch(setOpenDialogPayment(!false))
    dispatch(
      setDialogPayment({
        id: record?.id,
        maxPayment: record?.finalizationAmount - record?.finalizationPaid,
        username: record?.username
      })
    )
  }
  //================ EFFECT =================
  //================ REDNER =================
  const renderBtn = () => {
    if (record?.isActive) {
      return (
        <Button style={{ borderColor: 'red', color: 'red', width:"150px" }} onClick={handleClickActive}>
          Ngưng kích hoạt
        </Button>
      )
    }
    return (
      <Button type='primary' onClick={handleClickActive} style={{width:"150px"}}>
        Kích hoạt
      </Button>
    )
  }
  //================ MAIN ===================
  return (
    <Row>
      <Space size='large'>
        <Button type='primary' onClick={handleClickPayment} disabled={record?.finalizationAmount - record?.finalizationPaid <= 0}>
          Thanh toán
        </Button>
        {renderBtn()}
        <Button type='primary' danger onClick={handleClickDelete}>
          Xoá
        </Button>
      </Space>
    </Row>
  )
}

export default Action
