// ** React Imports
import { Fragment, SyntheticEvent, useContext, useState } from 'react'

// ** Next Import

// ** MUI Imports
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'

// ** Icons Imports
import LogoutVariant from 'mdi-material-ui/LogoutVariant'
import { LoginContext } from 'src/contexts/login'
import { useAuth } from 'src/store/auth/selector'
import { useNavigate } from 'react-router-dom'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = () => {
  // ** States
  const dataAuth = useAuth()
  const { useInfo } = dataAuth
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const { logout } = useContext(LoginContext)
  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    navigate("/");
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src={useInfo?.isAdmin ? "/images/logos/logo.png" : useInfo?.logo }
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ py: 2 }} onClick={() => handleLogout()}>
          <LogoutVariant sx={{ marginRight: 2, fontSize: '1.375rem', color: 'text.secondary' }} />
            Đăng xuất
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
