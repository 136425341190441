import { Box, Grid, Typography } from '@mui/material'
import { Button } from 'antd'

const Header = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
      <Grid item>
        <Typography variant='h5'>Tổng quan</Typography>
        <Typography>Thiết lập tạo tài khoản admin</Typography>
      </Grid>
      <Grid>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Button size='large'>Huỷ</Button>
          <Button
            size='large'
            type='primary'
            style={{ background: 'linear-gradient(96deg, #FA9406 6.19%, #FEBC1D 97.63%)', border: 'none' }}
            form='form-create-admin'
            key='submit'
            htmlType='submit'
          >
            Lưu
          </Button>
        </Box>
      </Grid>
    </Box>
  )
}

export default Header
