import { Avatar, Button, Col, Modal, Row, Typography, notification } from 'antd'
import { useAppDispatch } from 'src/store'
import { fetchListAdmin } from 'src/store/auth/action'
import { getDeleteAccount } from 'src/store/auth/fetchData'
import { useAuth } from 'src/store/auth/selector'
import { setDialogDelete, setOpenDialogDelete } from 'src/store/auth/slice'

const { Text } = Typography

const DialogDelete = () => {
  //================ VAR ====================
  //================ STATE ==================
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  const dataAuth = useAuth()
  const { isOpenDialogDelete, dialogDelete } = dataAuth
  //================ FUNCTION ===============
  const handleUpdateData = () => {
    dispatch(fetchListAdmin())
  }
  const handleCancel = () => {
    dispatch(setOpenDialogDelete(false))
    dispatch(
      setDialogDelete({
        username: '',
        logo: '',
        id: ''
      })
    )
  }
  const handleConfirm = async () => {
    const res = await getDeleteAccount(dialogDelete?.id)
    if (res?.status === 200) {
      notification.open({
        message: `Xoá tài khoản ${dialogDelete?.username} thành công.`,
        type: 'success'
      })
      handleCancel()
      handleUpdateData()
    } else {
      notification.open({
        message: `Xoá tài khoản ${dialogDelete?.username} không thành công.`,
        type: 'error'
      })
    }
  }
  //================ EFFECT =================
  //================ REDNER =================
  //================ MAIN ===================
  return (
    <Modal title="Xoá tài khoản" open={isOpenDialogDelete} onCancel={handleCancel} footer={null}>
      <Row justify='center' align='middle' gutter={[0, 24]}>
        <Col span='24'>
          <Row justify='center' align='middle'>
            <Avatar size={72} src={dialogDelete?.logo} />
          </Row>
        </Col>
        <Col span={24}>
          <Row justify='center' align='middle'>
            <Text style={{ fontSize: '16px' }}>
                Bạn có chắc chắn muốn xoá tài khoản{' '}
                <span style={{ fontWeight: 'bold' }}>{dialogDelete?.username}</span> ?
              </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-around' style={{ marginTop: '18px' }}>
        <Button style={{ width: '150px' }} onClick={handleCancel}>
          Huỷ
        </Button>
        <Button type='primary' style={{ width: '150px' }} onClick={handleConfirm}>
          Xác nhận
        </Button>
      </Row>
    </Modal>
  )
}

export default DialogDelete
