export enum API_URL {
    LOGIN = "/v1/auth-admin/login",
    LOGOUT = "/v1/auth-admin/logout",
    USER_INFO = "/v1/provider",
    CREATE_ADMIN = "/v1/provider",
    ADMIN_LIST = "/v1/provider/all",
    UPDATE_STATUS = "/v1/provider/status",
    DELETE = "/v1/provider/",
    PAYMENT = "/v1/provider/finalization",

    // voucher
    CREATE_VOUCHER = '/v1/voucher',
    ALL_VOUCHER = '/v1/voucher/all',
    VOUCHER = "v1/voucher/code/",
    UPDATE_VOUCHER = "/v1/voucher/active",
    DELETE_VOUCHER = '/v1/voucher/',
    ORDER_ADMIN_LIST = "/v1/orders/admin/list",
    STATICTIS = "/v1/orders/statictis",

    // URBOX 
    URBOX_DEPOSIT = "/v1/urbox-deposit",
    ADD_DEPOSIT = "/v1/urbox-deposit",
    EDIT_DEPOSIT = "/v1/urbox-deposit"
}