
//component

import { Divider, Typography } from "antd"
import { Container } from "./style"
import FormDeposit from "./component/deposit_form"
import DepositTable from "./component/deposit_table"
import DialogEdit from "./dialog/dialog_edit"
import { useEffect } from "react"
import { useAppDispatch } from "src/store"
import { fetchUrbox } from "src/store/deposit/action"

const { Title } = Typography

const Deposit = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchUrbox())
    }, [])
    return (
        <Container item>
            <FormDeposit />
            <Divider />
            <Title level={3}>Lịch sử ký quỹ</Title>
            <DepositTable />
            <DialogEdit />
        </Container>
    )
}

export default Deposit