import { Card, Col, Row } from "antd";
//components
import GeneralTableHeader from "./component/general_table_header";
import GeneralTableTable from "./component/general_table_table";
//actions
//selector
//function
//str

const GeneralTable = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE ------------------------
  // -------------------------- REDUX ------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT -----------------------
  // -------------------------- RENDER -----------------------
  // -------------------------- MAIN -------------------------
  return (
      <Row style={{marginTop:"2rem"}}>
        <Col span={24}>
            <Card style={{borderRadius:16}}>
                <Row gutter={[16,16]}>
                    <GeneralTableHeader />
                    <GeneralTableTable />
                </Row>
            </Card>
        </Col>
      </Row>
  );
};
export default GeneralTable;