import { Avatar, Button, Col, Modal, Row, Typography, notification } from 'antd'
import { useAppDispatch } from 'src/store'
import { fetchAllVoucher } from 'src/store/admin/action'
import { getDeleteVoucher } from 'src/store/voucher/fetchData'
import { useVoucher } from 'src/store/voucher/selector'
import { setDialogDeleteVoucher, setOpenDialogDeleteVoucher } from 'src/store/voucher/slice'

const { Text } = Typography

const DialogDeleteVoucher = () => {
  //================ VAR ====================
  //================ STATE ==================
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  const dataVoucher = useVoucher()
  const { isOpenDialogDeleteVoucher, deleteVoucher } = dataVoucher
  //================ FUNCTION ===============
  const handleUpdateData = () => {
    dispatch(fetchAllVoucher())
  }
  const handleCancel = () => {
    dispatch(setOpenDialogDeleteVoucher(false))
    dispatch(
        setDialogDeleteVoucher({deleteVoucher: {
          id: "",
          name: ""
        }})
    )
  }
  const handleConfirm = async () => {
    const res = await getDeleteVoucher(deleteVoucher?.id)
    if (res?.status === 200) {
      notification.open({
        message: `Xoá voucher ${deleteVoucher?.name} thành công.`,
        type: 'success'
      })
      handleCancel()
      handleUpdateData()
    } else {
      notification.open({
        message: `Xoá voucher ${deleteVoucher?.name} không thành công.`,
        type: 'error'
      })
    }
  }
  //================ EFFECT =================
  //================ REDNER =================
  //================ MAIN ===================
  return (
    <Modal title="Xoá tài khoản" open={isOpenDialogDeleteVoucher} onCancel={handleCancel} footer={null}>
      <Row justify='center' align='middle' gutter={[0, 24]}>
        <Col span='24'>
          <Row justify='center' align='middle'>
            <Avatar size={72} src="/images/pages/confirm.png" />
          </Row>
        </Col>
        <Col span={24}>
          <Row justify='center' align='middle'>
            <Text style={{ fontSize: '16px', textAlign:"center" }}>
                Bạn có chắc chắn muốn xoá voucher{' '}
                <span style={{ fontWeight: 'bold' }}>{deleteVoucher?.name}</span> ?
              </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-around' style={{ marginTop: '18px' }}>
        <Button style={{ width: '150px' }} onClick={handleCancel}>
          Huỷ
        </Button>
        <Button type='primary' style={{ width: '150px' }} onClick={handleConfirm}>
          Xác nhận
        </Button>
      </Row>
    </Modal>
  )
}

export default DialogDeleteVoucher
