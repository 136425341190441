import { Space, Tag } from 'antd'

const TableStatus = ({record}:{record: any}) => {
  //===================== VAR =========================
  //===================== STATE =======================
  //===================== REDUX =======================
  //===================== FUNCTION ====================

  //===================== EFFECT ======================
  //===================== REDNER ======================
  const renderStatus = () => {
    switch (record?.status) {
      case 'sold':
        return (
            <Tag color="volcano">Đã bán</Tag>
        )
      case 'used':
          return (
              <Tag color="volcano">Đã sử dụng</Tag>
          )
      default:
        return (
            <Tag color="green">Chưa bán</Tag>
        )
    }
  }
  //===================== MAIN ========================
  return (
    <Space>
        {renderStatus()}
    </Space>
  )
}

export default TableStatus
