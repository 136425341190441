import { Button } from 'antd'
import { useAppDispatch } from 'src/store'
import { VoucherItem } from 'src/store/admin/type'
import { setDialogDeleteVoucher, setOpenDialogDeleteVoucher } from 'src/store/voucher/slice'

const VouchertableDetele = ({ record }: { record: VoucherItem }) => {
  //================ VAR ====================
  //================ STATE ==================
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  //================ FUNCTION ===============
  const handleConfirm = () => {
    dispatch(setOpenDialogDeleteVoucher(!false))
    dispatch(
        setDialogDeleteVoucher({
          id: record?._id,
          name: record?.name
        })
    )
  }
  //================ EFFECT =================
  //================ REDNER =================
  //================ MAIN ===================
  return (
    <Button type='primary' danger onClick={handleConfirm} disabled={record?.amount !== record?.remainQuantity}>
      Xoá
    </Button>
  )
}

export default VouchertableDetele
