import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAllVoucher } from "./fetchData"
import { ListVoucher } from "./type"


export const fetchAllVoucher = createAsyncThunk<ListVoucher>(
    'storeAdmin/fetchAllVoucher',
    async () => {
        const resData = await getAllVoucher()
        return resData
    }
)
