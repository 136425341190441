import React, { useMemo, useState } from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const VoucherTableDescription = ({ description }: { description: string }) => {
  const isShowTextNote = description?.length > 30
  const [isShowMore, setShowMore ] = useState(false)
  const converDescription = description.split("\n")
  const isEndCount = useMemo(() => {
    if(isShowMore) {
      return description?.length
    }
    return 2
  },[isShowMore, description])
  const arr = [...converDescription.slice(0,isEndCount)]
  if (description) {
    return (
      <Text style={{ wordBreak: 'break-word' }}>
        { arr?.map((dt, index) => {
          return (
            <p key={index}>
              {dt}
            </p>
          )
        })

        }
        { isShowTextNote && !isShowMore &&
          '...'
        }
        { isShowTextNote &&
          <span style={{ color: 'blue', fontSize: '12px', cursor:"pointer" }} onClick={() => setShowMore(!isShowMore)}>
            { isShowMore ?
              "Rút gọn"
            :
              "Xem thêm"
            }
          </span>
        }
      </Text>
    )
  }
  return <Text>---</Text>
}

export default VoucherTableDescription
