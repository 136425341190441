import { Table, Col } from 'antd'
import { AdminInfoItem } from 'src/store/auth/type'
import { Logo } from './component/Logo'
import Description from './component/Description'
import Roles from './component/Roles'
import Status from './component/Status'
import Action from './component/Action'

const AdminListTable = ({listAdmin}:{listAdmin:AdminInfoItem[]}) => {

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render:(_: any, record:AdminInfoItem) => {
        return (
          <Logo record={record} />
        )
      }
    },
    {
      title: 'Đã thanh toán',
      dataIndex: 'finalizationPaid',
      key: 'finalizationPaid',
      render:(_: any, record:AdminInfoItem) => {
        return (
          <span>
            {
              Number(record?.finalizationPaid).toLocaleString('en', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
            } đ
          </span>
        )
      },
      width:150
    },
    {
      title: 'Tổng tiền đã bán',
      dataIndex: 'finalizationAmount',
      key: 'finalizationAmount',
      render:(_: any, record:AdminInfoItem) => {
        return (
          <span>
            {
              Number(record?.finalizationAmount).toLocaleString('en', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
            } đ
          </span>
        )
      },
      width:150
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      key: 'isActive',
      render:(_: any, record:AdminInfoItem) => {
        return (
          <Status record={record} />
        )
      }
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      render:(_: any, record:AdminInfoItem) => {
        return (
          <Description record={record} />
        )
      },
      width:300
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render:(_: any, record:AdminInfoItem) => {
        return (
          <Roles record={record} />
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render:(_: any, record:AdminInfoItem) => {
        return (
          <Action record={record} />
        )
      },
      fixed: 'right' as const,
    }
  ]

  return (
    <Col span={24}>
      <Table dataSource={listAdmin} columns={columns} scroll={{x:"max-content"}} />
    </Col>
  )
}

export default AdminListTable
