import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAdminList, getLogin, getLogout, getUserInfo } from "./fetchData"
import { AuthType, ListAdminType, UserInfoType } from "./type"


export const fetchLogin = createAsyncThunk<AuthType, { userName: string, passWord: string }>(
    'storeLogin/fetchLogin',
    async ({ userName, passWord }) => {
        const resData = await getLogin(userName, passWord)
        return { accessToken: resData?.accessToken, status: resData?.status }
    }
)

export const fetchLogout = createAsyncThunk(
    'storeLogin/fetchLogout',
    async () => {
        await getLogout()
    }
)

export const fetchDisableLoginFail = createAsyncThunk(
    'storeLogin/fetchDisableLoginFail',
    async () => {
        return false
    }
)

export const fetchDataUser = createAsyncThunk<UserInfoType>(
    'storeLogin/fetchDataUser',
    async () => {
        const res = await getUserInfo()
        return {
            useInfo: res.useInfo
        }
    }
)

export const fetchListAdmin = createAsyncThunk<ListAdminType>(
    'storeLogin/fetchListAdmin',
    async () => {
        const res = await getAdminList()
        return res
    }
)
