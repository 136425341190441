import { Col, Row, Select } from 'antd';
import { useAppDispatch } from 'src/store';
import { useAuth } from 'src/store/auth/selector';
import { setStatus } from 'src/store/auth/slice';

const Filter = () => {
    //================ VAR ====================
    const otions = [
        {label:"Tất cả", value: "all"},
        {label:"Đã kích hoạt", value: "active"},
        {label:"Ngưng kích hoạt", value: "deactive"}
    ]
    //================ STATE ==================
    //================ REDUX ==================
    const dispatch = useAppDispatch()
    const dataAuth = useAuth()
    const { active } = dataAuth
    
    //================ FUNCTION ===============
    const handleChange = (value: string) => {
        dispatch(setStatus(value))
    };
    //================ EFFECT =================
   
    //================ REDNER =================
    //================ MAIN ===================
    return (
        <Col span={24}>
            <Row justify="end">
                <Select
                    options={otions}
                    value={active}
                    style={{width:"150px"}}
                    onChange={handleChange}
                />
            </Row>
        </Col>
    )   
}

export default Filter