import { Row, Col } from 'antd'
import { useEffect, useMemo } from 'react'
import { useAppDispatch } from 'src/store'
import { fetchAllVoucher } from 'src/store/admin/action'
import { useAdmin } from 'src/store/admin/selector'
import VoucherTable from './component/VoucherTable'
import Filter from './component/Filter'
import DialogDeleteVoucher from './dialog/dialog_delete_voucher'

const VoucherManagement = () => {
  //================ VAR =============================
  //================ STATE ===========================
  //================ REDUX ===========================
  const dispatch = useAppDispatch()
  const dataAdmin = useAdmin()
  const { isLoading, listVoucher, selectPrice } = dataAdmin
  //================ FUNCTION ========================
  //================ EFFECT ==========================
  useEffect(() => {
    dispatch(fetchAllVoucher())
  },[dispatch])

  const dataTable = useMemo(() => {
    if(selectPrice === "all") {
      return listVoucher
    }
    return listVoucher?.filter((dt) => dt?.price === Number(selectPrice))
  },[listVoucher, selectPrice])
  //================ REDNER ==========================
  //================ MAIN ============================
  return (
    <Row style={{background:"white", padding:"12px", borderRadius:"12px"}} gutter={[0,24]}>
      <Col span={24} style={{marginTop:"1rem"}}>
          <Filter listVoucher={listVoucher} selectPrice={selectPrice} />
      </Col>
      <Col span={24}>
        <VoucherTable listVoucher={dataTable} isLoading={isLoading} />
      </Col>
      <DialogDeleteVoucher />
    </Row>
  )
}

export default VoucherManagement
