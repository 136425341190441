import { urboxApi } from "src/configs/api/urbox"
import { UrboxType } from "./type"


export const getUrbox = async ():Promise<{urbox: UrboxType[]}>=> {
    try {
        const res = await urboxApi.urboxDeposit()
        return {
            urbox: res?.data
        }
    } catch (error) {
        console.log(error)
        return {
            urbox: []
        }
    }
}

export const createUrboxDeposit = async (date: string, amount:number) => {
    try {
        const res = await urboxApi.createUrboxDeposit({date, amount})
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}

export const editUrboxDeposit = async (date: string, amount:number, id:string) => {
    try {
        const res = await urboxApi.editUrboxDeposit({date, amount, id})
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}