import { createSlice } from '@reduxjs/toolkit';
import { InitialStateVoucherType } from './type';
import { fetchVoucher } from './action';


const initialState: InitialStateVoucherType = {
    voucher: {
        code: "",
        status: "",
        price: 0,
        expireDate: ""
    },
    isOpenDialogConfirm: false,
    dialogConfirm: {
        code: "",
        price: 0,
    },
    isOpenDialogDeleteVoucher: false,
    deleteVoucher: {
        id: "",
        name: ""
    }
    
}
export const voucherSlice = createSlice({
    name: "voucherSlice",
    initialState,
    reducers: {
        setOpenDialogConfirm: (state, action) => {
            const isOpenDialogConfirm = action.payload;
            state.isOpenDialogConfirm = isOpenDialogConfirm;
        },
        setDialogConfirm: (state, action) => {
            const dialogConfirm = action.payload;
            state.dialogConfirm = dialogConfirm;
        },
        setOpenDialogDeleteVoucher: (state, action) => {
            const isOpenDialogDeleteVoucher = action.payload;
            state.isOpenDialogDeleteVoucher = isOpenDialogDeleteVoucher;
        },
        setDialogDeleteVoucher: (state, action) => {
            const deleteVoucher = action.payload;
            state.deleteVoucher = deleteVoucher;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchVoucher.fulfilled, (state, action) => {
            const data = action.payload
            state.voucher = data?.voucher
        })
    },
});
const { actions, reducer } = voucherSlice;

export const { setOpenDialogConfirm, setDialogConfirm, setOpenDialogDeleteVoucher, setDialogDeleteVoucher } = actions;

export default voucherSlice.reducer 
