import { Tag, Typography } from 'antd'
import _ from 'lodash'
import { AdminInfoItem } from 'src/store/auth/type'

const { Text } = Typography

const Status = ({ record }: { record: AdminInfoItem }) => {
    if(record?.isActive) {
        return (
            <Tag color="green">Đã kích hoạt</Tag>
        )
    }
    return (
        <Tag color="red">Ngưng kích hoạt</Tag>
    )
}

export default Status
