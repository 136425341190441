import { Card, Col, Image, Row, Space, Typography } from "antd";
//components
import { RepeatCycleIcon } from "src/component/Svg";
import { ContainerCurrency } from "../../style";
import { formatNumer } from "src/utils/formatNumber";
//actions
//selector
//function
//str

const { Text, Title, Link } = Typography

const GeneralStatisticalItem = ({item}: {item: {title: string, image: string, value: number|string, tag?: string, isExternalLink?:boolean, isShowDanger?:boolean}}) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE ------------------------
  // -------------------------- REDUX ------------------------
  // -------------------------- FUNCTION ---------------------
  const getTitleColor = () => {
    if(item?.isShowDanger) {
      if(Number(item?.value) < 1000000) {
        return "red"
      }
    }
    return "rgba(0, 0, 0, 0.88)"
  }
  // -------------------------- EFFECT -----------------------
  // -------------------------- RENDER -----------------------
  const renderIcon = () => {
    if(item?.tag) {
      return (
        <ContainerCurrency>{item?.tag}</ContainerCurrency>
      )
    }
    return (
      <RepeatCycleIcon />
    )
  }
  // -------------------------- MAIN -------------------------
  return (
      <Col xl={6} lg={8} md={12} xs={24}>
        <Card style={{borderRadius:16}}>
          <Space size="middle" style={{width:"100%"}} direction="vertical">
              <Image
                width={50}
                height={50}
                src={item?.image}
                preview={false}
              />
              <Text>{item?.title}</Text>
              <Row align='middle' justify="space-between" style={{height:"32px"}}>
                { item?.isExternalLink ? 
                  <Link href={item?.value?.toString() ?? ""} target="_blank">
                    Xem thống kê truy cập
                  </Link>
                :
                  <Title level={4} style={{margin:0, color: getTitleColor()}} >{formatNumer(item?.value)}</Title>
                }
                {renderIcon()}
              </Row>
          </Space>
        </Card>
      </Col>
  );
};
export default GeneralStatisticalItem;