import { Table, Col, Row } from 'antd'
import { useMemo } from 'react'
import dayjs from "dayjs"
import { useVoucher } from 'src/store/voucher/selector'
import TableStatus from './component/TableStatus'
import TableAction from './component/TableAction'
import TablePrice from './component/TablePrice'


const AdminListTable = () => {

//===================== VAR =========================
const columns = [
    {
      title: 'Mã voucher',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Giá trị',
      dataIndex: '',
      key: '',
      render:(_:any, record:any) => <TablePrice record={record} />
      
    },
    {
      title: 'Trạng thái',
      dataIndex: '',
      key: '',
      render: (_:any, record:any) => <TableStatus record={record} />
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: '',
      key: '',
      render: (_:any, record:any) => {
        return (
          <span>{dayjs(record?.expireDate).format("DD-MM-YYYY")}</span>
        )
      }
    },
    {
        title: '',
        dataIndex: '',
        key: '',
        render:(_:any, record: any) => <TableAction record={record} />
    }
  ]
  //===================== STATE =======================
  //===================== REDUX =======================
  const dataVoucher = useVoucher()
  const { voucher } = dataVoucher
  //===================== FUNCTION ====================
  //===================== EFFECT ======================
  //===================== REDNER ======================
  const dataTable = useMemo(() => {
    const arr = [voucher]
    return arr.filter((dt) => dt?.code)

  },[voucher])
  //===================== MAIN ========================
  return (
    <Row style={{marginTop:"1.5rem"}}>
      <Col span={24}>
        <Table dataSource={dataTable} columns={columns} pagination={false} scroll={{x:"max-content"}} />
      </Col>
    </Row>
  )
}

export default AdminListTable
