import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const VouchertableAmount = ({ value, isShowUnit }: { value: string | number | undefined, isShowUnit?:boolean }) => {
  if (value) {
    return (
      <Text>
        {Number(value).toLocaleString('en', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })} {isShowUnit&& "đ"}
      </Text>
    )
  }
  return <Text>0</Text>
}

export default VouchertableAmount
