import { Table, Typography } from "antd";
import React from "react";
import { formatNumer } from "src/utils/formatNumber";

//components
//actions
//selector
//function
//str

const { Text } = Typography

const DepositTableFooter = ({sumAmount}:{sumAmount:number}) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE ------------------------
  // -------------------------- REDUX ------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT -----------------------
  // -------------------------- RENDER -----------------------
  // -------------------------- MAIN -------------------------

  return (
    <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          <Text>Tổng tiền đã ký quỹ</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          <Text>{formatNumer(sumAmount)}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}/>
      </Table.Summary.Row>
  );
};
export default DepositTableFooter;
