import { ReactNode } from "react";
import { useAuth } from "src/store/auth/selector";
import LoginPage from "src/views/Login";

const LoginLayout = ({ children }: {children: ReactNode}) => {
    const dataAuth = useAuth()

    if ( !dataAuth?.accessToken) return <LoginPage />
    
	return (
        <div>
            {children}
        </div>
    )
};

export default LoginLayout
