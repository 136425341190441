import { createSlice } from '@reduxjs/toolkit';
import { InitialStateType } from './type';
import { fetchUrbox } from './action';

const initialState: InitialStateType = {
    isOpenDialog: false,
    dialogEdit: {
        amount: 0,
        date: "",
        id: ""
    },
    isLoading: false,
    urbox: []
}
export const depositSlice = createSlice({
    name: "depositSlice",
    initialState,
    reducers: {
        setOpenDialog: (state, action) => {
            const isOpenDialog = action.payload;
            state.isOpenDialog = isOpenDialog;
        },
        setDialogEdit: (state, action) => {
            const dialogEdit = action.payload;
            state.dialogEdit = dialogEdit;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchUrbox.pending, (state) => {
            state.isLoading = !false
        })
        builder.addCase(fetchUrbox.fulfilled, (state, action) => {
            const data = action.payload
            state.urbox = data?.urbox
            state.isLoading = false
        })
        builder.addCase(fetchUrbox.rejected, (state) => {
            state.isLoading = false
        })
    },
});
const { actions, reducer } = depositSlice;

export const { setOpenDialog, setDialogEdit } = actions;

export default depositSlice.reducer 
