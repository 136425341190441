// ** Icon imports

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'
import { VoucherIcon, CreateVoucherIcon, CreateAdminIcon, AdminListIcon, VoucherListIcon, GeneralIcon, DepositIcon } from 'src/component/Svg'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: 'Voucher',
      icon: VoucherIcon,
      path: '/'
    },
    {
      title: 'Tạo Voucher',
      icon: CreateVoucherIcon,
      path: '/create-voucher'
    },
    {
      title: 'Quản lý Voucher',
      icon: VoucherListIcon,
      path: '/voucher-management'
    }
  ]
} 

export const navigationAdmin = (): VerticalNavItemsType => {
  return [
    {
      title: 'Tổng quan',
      icon: GeneralIcon,
      path: '/general'
    },
    {
      title: 'Tạo admin',
      icon: CreateAdminIcon,
      path: '/'
    },
    {
      title: 'Danh sách Admin',
      icon: AdminListIcon,
      path: '/admin-list'
    },
    {
      title: 'Ký quỹ',
      icon: DepositIcon,
      path: '/deposit'
    }
  ]
}

export default navigation
