import { Typography } from 'antd'

const { Text } = Typography

const TablePrice = ({record}:{record: any}) => {
  //===================== VAR =========================
  //===================== STATE =======================
  //===================== REDUX =======================
  //===================== FUNCTION ====================

  //===================== EFFECT ======================
  //===================== REDNER ======================
  //===================== MAIN ========================
  return (
    <Text>
      {
        Number(record?.price).toLocaleString('en', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      } đ
    </Text>
  )
}

export default TablePrice
