import { Space, Button } from "antd"
import { useAppDispatch } from "src/store"
import { setDialogEdit, setOpenDialog } from "src/store/deposit/slice"

const DepositTableAction = ({amount, date, id}: {amount: number, date: string, id: string}) => {
    // ================== VAR =====================
    // ================== STATE ===================
    // ================== REDUX ===================
    const dispatch = useAppDispatch()
    // ================== FUNCTION ================
    const handleClick = () => {
        dispatch(setOpenDialog(!false))
        dispatch(setDialogEdit({amount, date, id }))
    }
    // ================== EFFECT ==================
    // ================== RENDER ==================
    // ================== MAIN ====================
    return (
        <Space>
            <Button type="primary" onClick={handleClick}>Sửa</Button>
        </Space>
    )
}

export default DepositTableAction