// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { matchPath, useLocation } from "react-router-dom";
// ** Icons Imports
import Menu from 'mdi-material-ui/Menu';

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext';

// ** Components
import { useMemo } from 'react';
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown';
import { NavLink } from 'src/@core/layouts/types';
import { Typography } from '@mui/material';

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void,
  verticalNavItems?: NavLink[] | undefined
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, toggleNavVisibility, verticalNavItems } = props
  const { pathname } = useLocation();
 
  const findItemm = useMemo(() => {
    const find = verticalNavItems?.find((dt) => {
      if(dt?.path) {
        return matchPath( pathname, dt?.path )
      }
    })
    return find
  },[pathname, verticalNavItems])
  // ** Hook
  const hiddenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: "#fff", padding:"16px" }}>
      <Typography variant="h5">{findItemm?.title}</Typography>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton
            color='inherit'
            onClick={toggleNavVisibility}
            sx={{ ml: -2.75, ...(hiddenSm ? {} : { mr: 3.5 }) }}
          >
            <Menu />
          </IconButton>
        ) : null}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}
        <UserDropdown />
      </Box>
    </Box>
  )
}

export default AppBarContent
