import { Button, Col, Modal, Row, Typography, notification, Image } from 'antd'
import { useAppDispatch } from 'src/store'
import { fetchVoucher } from 'src/store/voucher/action'
import { getUpdateVoucher } from 'src/store/voucher/fetchData'
import { useVoucher } from 'src/store/voucher/selector'
import { setDialogConfirm, setOpenDialogConfirm } from 'src/store/voucher/slice'

const { Text } = Typography

const DialogConfirm = () => {
  //================ VAR ====================
  //================ STATE ==================
  //================ REDUX ==================
  const dispatch = useAppDispatch()
  const dataVoucher = useVoucher()
  const { isOpenDialogConfirm, dialogConfirm } = dataVoucher
  //================ FUNCTION ===============
  const handleUpdateData = () => {
    dispatch(fetchVoucher({code:dialogConfirm?.code}))
  }
  const handleCancel = () => {
    dispatch(setOpenDialogConfirm(false))
    dispatch(
        setDialogConfirm({
            code: "",
            price: 0,
      })
    )
  }
  const handleConfirm = async() => {
    const res = await getUpdateVoucher(dialogConfirm?.code)
      if(res?.status === 201) {
        notification.open({
          message: `Kích hoạt voucher ${dialogConfirm?.code} thành công.`,
          type: "success"
        });
        handleCancel()
        handleUpdateData()
      } else {
        notification.open({
          message: `Kích hoạt voucher ${dialogConfirm?.code} không thành công.`,
          type: "error"
        });
      }
  }
  //================ EFFECT =================
  //================ REDNER =================
  //================ MAIN ===================
  return (
    <Modal title="Kích hoạt voucher" open={isOpenDialogConfirm} onCancel={handleCancel} footer={null}>
      <Row justify='center' align='middle' gutter={[0, 32]}>
        <Col span={24}>
          <Row justify="center">
          <Image
          width={200}
          src="/images/pages/confirm.png"
          preview={false}
        />
          </Row>
        </Col>
        <Col span={24}>
          <Row justify='center' align='middle'>
            <Text style={{fontSize:"16px"}}>Bạn có chắc chắn muốn kích hoạt voucher <span style={{fontWeight:"bold"}}>{dialogConfirm?.code}</span> ?</Text>
          </Row>
        </Col>
      </Row>
      <Row justify="space-around" style={{marginTop:"18px"}}>
          <Button style={{width:"150px"}} onClick={handleCancel}>Huỷ</Button>
          <Button type='primary' style={{width:"150px",background: "linear-gradient(96deg, #FA9406 6.19%, #FEBC1D 97.63%)", border:"none"}} onClick={handleConfirm}>Xác nhận</Button>
      </Row>
    </Modal>
  )
}

export default DialogConfirm
