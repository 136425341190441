import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import type { GetProp, UploadProps } from 'antd'
import { Divider, Form, Space, Typography, Upload, message } from 'antd'
import { useState } from 'react'

//components
//actions
//selector
//type

//function
//str

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const { Text } = Typography
const FormCreateLogo = ({ form }: { form: any }) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE -------------------------
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  const checkFile = (file: { type: string; size: number }) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('Bạn chỉ có thể tải lên tệp JPG/PNG!')
    }

    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Hình ảnh phải nhỏ hơn 5 MB!')
    }

    return isJpgOrPng && isLt5M
  }
  const handleChange: UploadProps['onChange'] = info => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, url => {
        setLoading(false)
        setImageUrl(url)
        form.setFieldValue("logo", info.file);
      })
    }
  }
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )
  // -------------------------- MAIN ---------------------------
  return (
    <>
      <Divider />
      <Form.Item
        label='Logo'
        name='logo'
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
      >
        <Space direction='vertical'>
          <Upload
            name='avatar'
            className='avatar-uploader'
            listType='picture-card'
            showUploadList={false}
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            beforeUpload={checkFile}
            onChange={handleChange}
          >
            {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%', height: '100%', overflow:"hidden" }} /> : uploadButton}
          </Upload>
          <Text style={{ fontSize: '12px', color: '#808897' }}>JPG hoặc PNG. Kích thước 512x512 pixels</Text>
        </Space>
      </Form.Item>
    </>
  )
}
export default FormCreateLogo
