import { createAsyncThunk } from "@reduxjs/toolkit"
import { getVoucher } from "./fetchData"
import { Voucher } from "./type"


export const fetchVoucher = createAsyncThunk<Voucher, { code: string }>(
    'storeVoucher/fetchVoucher',
    async ({ code }) => {
        if (code) {
            const resData = await getVoucher(code)
            return resData
        }
        return {
            voucher: {
                code: "",
                status: "",
                price: 0,
                expireDate: ""
            }
        }
    }
)
