
//component
import { useEffect } from "react"
import dayjs from "dayjs"
import GeneralStatistical from "./component/general_statistical"
import GeneralTable from "./component/general_table"
import { Container } from "./style"
import { useGeneralSelector } from "src/store/general/selector"
import { useAppDispatch } from "src/store"
import { fetchOrderAdminList, fetchStatictis, fetchTotalOrderByDate } from "src/store/general/action"
import useRefresh from "src/hooks/useRefresh"

const General = () => {
    const { slowRefresh } = useRefresh()
    const dispatch = useAppDispatch()
    const { search, startDate, toDate, pagination } = useGeneralSelector()
    useEffect(() => {
        if(startDate && toDate) {
            const skip = (pagination?.current - 1) * pagination?.pageSize
            dispatch(fetchOrderAdminList({
                skip,
                limit: pagination?.pageSize,
                fromDate: dayjs(startDate)?.toISOString(),
                toDate: dayjs(toDate)?.toISOString(),
                search: search
            }))
        }
        
    },[dispatch, search, startDate, toDate, pagination])

    useEffect(() => {
        dispatch(fetchStatictis())
        dispatch(fetchTotalOrderByDate())
        
    },[dispatch, slowRefresh])
    return (
        <Container item>
           <GeneralStatistical />
           <GeneralTable />
        </Container>
    )
}

export default General