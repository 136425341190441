import { Row, Select } from 'antd'
import React, { useMemo } from 'react'
import { useAppDispatch } from 'src/store'
import { setSelectPrice } from 'src/store/admin/slice'
import { VoucherItem } from 'src/store/admin/type'

const Filter = ({ listVoucher, selectPrice }: { listVoucher: VoucherItem[], selectPrice: string }) => {
  //================ VAR =============================
  //================ STATE ===========================
  //================ REDUX ===========================
  const dispatch = useAppDispatch()
  //================ FUNCTION ========================
  const handleSelectPrice = (newValue:string) => {
    dispatch(setSelectPrice(newValue))
  }
  //================ EFFECT ==========================
    const listPrice = useMemo(() => {
        const sorted = [...listVoucher]?.sort((a,b) => a?.price - b?.price)
        const prices = sorted?.map((dt) => {
            return {
                label: Number(dt?.price).toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }),
                value: dt?.price?.toString()
            }
        })
        return [{label:"Tất cả", value:"all"}, ...prices]
    },[listVoucher])
  //================ REDNER ==========================
  //================ MAIN ============================
  return (
    <Row justify='end'>
      <Select value={selectPrice} style={{ width: '150px' }} options={listPrice} onChange={handleSelectPrice} />
    </Row>
  )
}
export default Filter
