import { Route, Routes } from 'react-router-dom'

import { useEffect } from 'react'
import { useAppDispatch } from './store'
import { fetchDataUser } from './store/auth/action'
import { useAuth } from './store/auth/selector'
import CreateAdmin from './views/CreateAdmin'
import CreateVoucher from './views/CreateVoucher'
import Voucher from './views/Voucher'
import AdminListManagement from './views/AdminListManagemenet'
import VoucherManagement from './views/VoucherManagement'
import General from "./views/General"
import Deposit from "./views/Deposit"

const App = () => {
  //================== VAR ================
  //================== STATE ================
  //================== REDUX ================
  const dispatch = useAppDispatch()
  const dataUser = useAuth()
  const { useInfo } = dataUser
  //================== FUNCTION ================
  //================== EFFECT ================
  useEffect(() => {
    dispatch(fetchDataUser())
  }, [dispatch])
  //================== RENDER ================
  //================== MAIN ================
  return (
    <>
      {useInfo?.isAdmin ? (
        <Routes>
          <Route path='/' element={<CreateAdmin />} />
          <Route path='/admin-list' element={<AdminListManagement />} />
          <Route path='/general' element={<General />} />
          <Route path='/deposit' element={<Deposit />} />
        </Routes>
      ) : (
        <Routes>
          <Route path='/' element={<Voucher />} />
          <Route path='/create-voucher' element={<CreateVoucher />} />
          <Route path='/voucher-management' element={<VoucherManagement />} />
        </Routes>
      )}
    </>
  ) 
}

export default App
