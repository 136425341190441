import { createSlice } from '@reduxjs/toolkit';
import { KEY_LOGIN } from 'src/configs';
import { fetchDataUser, fetchDisableLoginFail, fetchListAdmin, fetchLogin, fetchLogout } from './action';
import { InitialStateAuthType } from './type';

const initialState: InitialStateAuthType = {
    accessToken:localStorage?.getItem(KEY_LOGIN),
    isLoading: false,
    isLoginFail: false,
    useInfo: {
        username: "",
        roles: [],
        isAdmin: false,
        logo:"",
        brandName: ""
   },
   listAdmin: [],
   active: "all",
   isOpenDialogActive: false,
   dialogActive: {
        type: null,
        username: "",
        logo: ""
   },
   isOpenDialogDelete: false,
   dialogDelete: {
        username: "",
        logo: "",
        id: ""
   },
   isOpenDialogPayment: false,
   dialogPayment: {
        id: "",
        maxPayment: 0,
        username: ""
   }

}
export const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        setStatus: (state, action) => {
            const active = action.payload;
            state.active = active;
        },
        setOpenDialogActive: (state, action) => {
            const isOpenDialogActive = action.payload;
            state.isOpenDialogActive = isOpenDialogActive;
        },
        setDialogActive: (state, action) => {
            const dialogActive = action.payload;
            state.dialogActive = dialogActive;
        },
        setOpenDialogDelete: (state, action) => {
            const isOpenDialogDelete = action.payload;
            state.isOpenDialogDelete = isOpenDialogDelete;
        },
        setDialogDelete: (state, action) => {
            const dialogDelete = action.payload;
            state.dialogDelete = dialogDelete;
        },
        setOpenDialogPayment: (state, action) => {
            const isOpenDialogPayment = action.payload;
            state.isOpenDialogPayment = isOpenDialogPayment;
        },
        setDialogPayment: (state, action) => {
            const dialogPayment = action.payload;
            state.dialogPayment = dialogPayment;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchLogin.pending, (state) => {
            state.isLoading = !false
        })
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            const dataLogin = action.payload
            state.accessToken = dataLogin?.accessToken
            state.isLoading = false,
            state.isLoginFail = Number(dataLogin?.status) === 201 ? !false : false
        })
        builder.addCase(fetchLogin.rejected, (state) => {
            state.isLoading = false
        })
        builder.addCase(fetchLogout.fulfilled, (state, action) => {
            state.accessToken = null
        })
        builder.addCase(fetchDisableLoginFail.fulfilled, (state, action) => {
            const data = action.payload
            state.isLoginFail = data
        })
        builder.addCase(fetchDataUser.fulfilled, (state, action) => {
            const dataUser = action.payload
            state.useInfo = dataUser?.useInfo
        })
        builder.addCase(fetchListAdmin.pending, (state, action) => {
            state.isLoading = !false
        })
        builder.addCase(fetchListAdmin.fulfilled, (state, action) => {
            const data = action.payload
            state.listAdmin = data?.listAdmin
            state.isLoading = false
        })
        builder.addCase(fetchListAdmin.rejected, (state) => {
            state.isLoading = false
        })
    },
});
const { actions, reducer } = authSlice;

export const { 
    setStatus, 
    setOpenDialogActive, 
    setDialogActive, 
    setOpenDialogDelete, 
    setDialogDelete, 
    setOpenDialogPayment, 
    setDialogPayment 
} = actions;

export default authSlice.reducer 
