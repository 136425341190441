import { voucherApi } from "src/configs/api/voucher";
import { Voucher } from "./type";


export const getVoucher = async (code:string):Promise<Voucher> => {
    try {
        const res = await voucherApi.voucher({ code })
        return {
            voucher: {
                code: res?.data?.code,
                status: res?.data?.status,
                price: res?.data?.voucherSet?.value,
                expireDate: res?.data?.voucherSet?.expireDate,
            } 
        }
    } catch (error) {
        console.log(error)
        return {
            voucher: {
                code: "",
                status: "",
                price: 0,
                expireDate: ""
            }
        }
    }
}


export const getUpdateVoucher = async (code:string) => {
    try {
        const res = await voucherApi.updateVoucher({ code })
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}


export const getDeleteVoucher = async (id:string) => {
    try {
        const res = await voucherApi.deleteVoucher({ id })
        return {
            status: res?.statusCode
        }
    } catch (error) {
        console.log(error)
        return {
            status: 400
        }
    }
}
