import React from 'react'

const Icon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='54' height='68' viewBox='0 0 54 68' fill='none'>
      <mask
        id='mask0_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='14'
        y='21'
        width='26'
        height='29'
      >
        <path
          d='M39.4341 28.5188L38.9549 28.2449L27.0261 21.3649L15.1487 28.2107L14.6182 28.5188V29.7339V42.8436L27.0261 49.9974L39.4341 42.8436V29.7339V28.5188ZM37.3462 35.1421L27.0261 41.0979L16.7232 35.1421V29.7339L17.4421 29.3232L17.7672 29.1349L27.0261 23.7952L27.8134 24.2573L30.6031 25.866L21.8747 30.9148L21.3441 31.2229L19.7696 32.1299L21.8747 33.3451L23.9798 32.1299L25.6228 31.1886L32.7253 27.0983L36.3022 29.1691L28.1557 33.8756L24.9382 35.6897L27.0433 36.9049L37.3462 30.949V35.1421ZM27.0261 46.3692L16.7232 40.4133V38.6334L27.0261 44.5893L37.3291 38.6334V40.4133L27.0261 46.3692Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_2655_1411)'>
        <path d='M39.4338 21.3665H14.6349V50.0161H39.4338V21.3665Z' fill='url(#paint0_linear_2655_1411)' />
      </g>
      <mask
        id='mask1_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='30'
        y='16'
        width='24'
        height='12'
      >
        <path
          d='M45.6253 16.2386C44.7867 16.9574 43.9824 17.2313 43.1609 17.5736C41.7061 18.1726 39.0876 18.8914 34.8261 18.8914C33.8848 18.8914 32.8922 18.8572 31.8653 18.7887C31.6257 19.4219 31.1123 20.175 30.0683 20.6713L42.4078 27.8251L53.6863 21.2703L45.7623 16.1188C45.728 16.1531 45.6767 16.1873 45.6253 16.2386Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_2655_1411)'>
        <path
          d='M29.537 16.1224L55.0033 16.054L55.5339 27.9656L30.0675 28.0512L29.537 16.1224Z'
          fill='url(#paint1_linear_2655_1411)'
        />
      </g>
      <mask
        id='mask2_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='1'
        y='15'
        width='23'
        height='13'
      >
        <path
          d='M1.11499 19.5017L12.7186 27.2032L23.8772 20.734C23.4494 20.5628 23.0386 20.3575 22.6621 20.1007C22.491 20.1007 22.3027 20.1179 22.1144 20.1179C19.804 20.1179 16.9459 19.5017 13.6428 18.2695C11.435 17.4651 9.55242 16.695 8.06346 15.4969L1.11499 19.5017Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_2655_1411)'>
        <path
          d='M1.08069 15.4997L23.8772 15.4313L23.9114 27.805L1.11492 27.8735L1.08069 15.4997Z'
          fill='url(#paint2_linear_2655_1411)'
        />
      </g>
      <mask
        id='mask3_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='42'
        y='23'
        width='12'
        height='37'
      >
        <path d='M42.4802 30.4924V59.0051L53.964 52.262V23.7493L42.4802 30.4924Z' fill='white' />
      </mask>
      <g mask='url(#mask3_2655_1411)'>
        <path
          d='M41.4706 21.37L53.9641 21.3358L54.0668 59.4154L42.4803 59.0389L41.4706 21.37Z'
          fill='url(#paint3_linear_2655_1411)'
        />
      </g>
      <mask
        id='mask4_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='14'
        y='45'
        width='26'
        height='23'
      >
        <path
          d='M14.6187 59.5968L27.0267 68L39.4518 60.6579V45.2891L27.0267 52.4601L14.6187 45.2891V59.5968Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask4_2655_1411)'>
        <path
          d='M14.5665 43.9744L39.451 45.0183L39.5023 69.0642L14.6179 68.0202L14.5665 43.9744Z'
          fill='url(#paint4_linear_2655_1411)'
        />
      </g>
      <mask
        id='mask5_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='23'
        y='4'
        width='7'
        height='9'
      >
        <path
          d='M23.98 6.30265C24.8186 7.87718 25.7599 10.0507 26.1535 12.4125C26.2562 12.4125 26.3589 12.4125 26.4445 12.4125C26.6498 12.4125 26.8381 12.4125 27.0264 12.4296C27.5056 10.5128 28.464 8.28792 29.7989 6.14862L27.0264 4.53986L23.98 6.30265Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask5_2655_1411)'>
        <path
          d='M23.9442 4.54006L29.7973 4.52295L29.8144 12.4298L23.9784 12.4469L23.9442 4.54006Z'
          fill='url(#paint5_linear_2655_1411)'
        />
      </g>
      <mask
        id='mask6_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='0'
        y='21'
        width='13'
        height='38'
      >
        <path d='M0.0872321 49.9048L12.4952 58.3251V29.7953L0.0358887 21.3408L0.0872321 49.9048Z' fill='white' />
      </mask>
      <g mask='url(#mask6_2655_1411)'>
        <path
          d='M-0.013916 21.3448L12.4968 21.3105L12.5994 58.3291L0.0887708 58.4147L-0.013916 21.3448Z'
          fill='url(#paint6_linear_2655_1411)'
        />
      </g>
      <mask
        id='mask7_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='0'
        y='20'
        width='1'
        height='1'
      >
        <path d='M0.09021 20.0966V20.1318L0.125388 20.079L0.09021 20.0966Z' fill='white' />
      </mask>
      <g mask='url(#mask7_2655_1411)'>
        <path d='M0.124073 20.0797H0.0898438V20.1482H0.124073V20.0797Z' fill='url(#paint7_linear_2655_1411)' />
      </g>
      <mask
        id='mask8_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='7'
        y='0'
        width='41'
        height='20'
      >
        <path
          d='M29.7447 14.5345C29.7447 14.5345 30.4464 13.422 30.9084 12.8059C31.4732 12.0529 32.6712 10.5468 34.8277 8.56151C36.9841 6.59335 39.3459 6.09703 40.9033 6.74738C42.4607 7.39773 43.3507 9.00649 43.3164 10.4612C43.2993 10.9918 43.0255 12.07 42.4265 12.6861C41.0573 14.0895 38.3532 14.8425 34.4511 14.8425C33.0306 14.8425 31.4732 14.7398 29.7447 14.5345ZM10.7818 11.5394C10.0459 10.2901 10.0117 8.47594 11.0728 7.53464C12.6131 6.19972 14.8037 6.14837 16.4467 7.22658C18.0897 8.3048 20.9478 10.9575 23.2583 15.9892C23.1556 16.0063 23.0358 16.0063 22.8989 16.0063C20.5029 16.0063 12.3564 14.2093 10.7818 11.5394ZM12.305 0.466371C10.508 1.76707 6.50322 5.56648 7.10223 9.58838C7.3076 11.0089 7.78681 12.07 8.47138 12.9086C9.75497 14.4831 11.7916 15.3217 14.3588 16.2801C18.3293 17.752 21.273 18.1798 23.2583 17.9231C24.9868 19.395 27.4 19.395 28.8718 18.8644C30.3437 18.351 30.0014 17.2214 29.7447 16.4513C37.1723 17.307 40.9546 16.1946 42.358 15.6127C43.0768 15.3217 44.1722 14.6885 44.7712 14.1579C45.5584 13.4905 46.2772 12.3438 46.8591 10.3072C47.9031 6.67892 43.9154 1.15095 42.803 0.551943C41.6734 -0.0470627 39.6882 -0.834328 35.8888 2.2805C32.0722 5.39534 29.4024 10.581 28.8718 13.9526C28.8718 13.9526 28.2728 14.2093 28.1017 14.7227C27.4171 14.466 25.6885 14.466 24.2167 14.7227C24.3023 9.62261 20.3317 4.09464 18.6887 2.45165C17.4565 1.23652 15.5054 0.00428073 13.8453 0.00428073C13.2805 0.00428073 12.7671 0.141196 12.305 0.466371Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask8_2655_1411)'>
        <path
          d='M6.45404 -0.814342L47.8882 -0.934143L47.9395 19.3978L6.50538 19.5176L6.45404 -0.814342Z'
          fill='url(#paint8_linear_2655_1411)'
        />
      </g>
      <mask
        id='mask9_2655_1411'
        style={{maskType:"luminance"}}
        maskUnits='userSpaceOnUse'
        x='2'
        y='20'
        width='1'
        height='1'
      >
        <path d='M2.73749 20.0966V20.1318L2.77267 20.079L2.73749 20.0966Z' fill='white' />
      </mask>
      <g mask='url(#mask9_2655_1411)'>
        <path d='M2.77208 20.0798H2.73785V20.1482H2.77208V20.0798Z' fill='url(#paint9_linear_2655_1411)' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_2655_1411'
          x1='14.6299'
          y1='35.6912'
          x2='39.4362'
          y2='35.6912'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='0.4847' stop-color='#A55FE2' />
          <stop offset='1' stop-color='#FF4982' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2655_1411'
          x1='-4.76346'
          y1='22.1649'
          x2='56.6508'
          y2='21.9953'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_2655_1411'
          x1='-0.196943'
          y1='21.697'
          x2='53.9613'
          y2='21.5474'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_2655_1411'
          x1='-0.105963'
          y1='40.5293'
          x2='54.0452'
          y2='40.3797'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_2655_1411'
          x1='-0.070071'
          y1='56.5815'
          x2='54.1021'
          y2='56.4319'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_2655_1411'
          x1='-0.219316'
          y1='8.57346'
          x2='53.9669'
          y2='8.42377'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_2655_1411'
          x1='-0.0922862'
          y1='39.8882'
          x2='54.0939'
          y2='39.7385'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_2655_1411'
          x1='0.0897196'
          y1='20.1071'
          x2='0.1249'
          y2='20.1071'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_2655_1411'
          x1='-0.220284'
          y1='9.36588'
          x2='53.9659'
          y2='9.21619'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_2655_1411'
          x1='2.73463'
          y1='20.1072'
          x2='2.76981'
          y2='20.1072'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#00C5DC' />
          <stop offset='1' stop-color='#3791E0' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
