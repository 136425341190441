import React from 'react'

const Icon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M21.97 2.33C21.25 1.51 20.18 1 19 1C17.88 1 16.86 1.46 16.13 2.21C15.71 2.64 15.39 3.16 15.2 3.74C15.07 4.14 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.71 16.34 7.97C17.04 8.61 17.97 9 19 9C19.44 9 19.86 8.93 20.25 8.79C21.17 8.5 21.94 7.87 22.42 7.06C22.63 6.72 22.79 6.33 22.88 5.93C22.96 5.63 23 5.32 23 5C23 3.98 22.61 3.04 21.97 2.33ZM20.49 5.73H19.75V6.51C19.75 6.92 19.41 7.26 19 7.26C18.59 7.26 18.25 6.92 18.25 6.51V5.73H17.51C17.1 5.73 16.76 5.39 16.76 4.98C16.76 4.57 17.1 4.23 17.51 4.23H18.25V3.52C18.25 3.11 18.59 2.77 19 2.77C19.41 2.77 19.75 3.11 19.75 3.52V4.23H20.49C20.9 4.23 21.24 4.57 21.24 4.98C21.24 5.39 20.91 5.73 20.49 5.73Z'
        fill='white'
      />
      <path
        d='M22 12C22 10.69 21.75 9.43 21.28 8.28C20.97 8.5 20.62 8.67 20.25 8.79C20.14 8.83 20.03 8.86 19.91 8.89C20.29 9.85 20.5 10.9 20.5 12C20.5 14.32 19.56 16.43 18.04 17.97C17.75 17.6 17.38 17.26 16.94 16.97C14.23 15.15 9.79 15.15 7.06 16.97C6.62 17.26 6.26 17.6 5.96 17.97C4.44 16.43 3.5 14.32 3.5 12C3.5 7.31 7.31 3.5 12 3.5C13.09 3.5 14.14 3.71 15.1 4.09C15.13 3.97 15.16 3.86 15.2 3.74C15.32 3.37 15.49 3.03 15.72 2.72C14.57 2.25 13.31 2 12 2C6.49 2 2 6.49 2 12C2 14.9 3.25 17.51 5.23 19.34C5.23 19.35 5.23 19.35 5.22 19.36C5.32 19.46 5.44 19.54 5.54 19.63C5.6 19.68 5.65 19.73 5.71 19.77C5.89 19.92 6.09 20.06 6.28 20.2C6.35 20.25 6.41 20.29 6.48 20.34C6.67 20.47 6.87 20.59 7.08 20.7C7.15 20.74 7.23 20.79 7.3 20.83C7.5 20.94 7.71 21.04 7.93 21.13C8.01 21.17 8.09 21.21 8.17 21.24C8.39 21.33 8.61 21.41 8.83 21.48C8.91 21.51 8.99 21.54 9.07 21.56C9.31 21.63 9.55 21.69 9.79 21.75C9.86 21.77 9.93 21.79 10.01 21.8C10.29 21.86 10.57 21.9 10.86 21.93C10.9 21.93 10.94 21.94 10.98 21.95C11.32 21.98 11.66 22 12 22C12.34 22 12.68 21.98 13.01 21.95C13.05 21.95 13.09 21.94 13.13 21.93C13.42 21.9 13.7 21.86 13.98 21.8C14.05 21.79 14.12 21.76 14.2 21.75C14.44 21.69 14.69 21.64 14.92 21.56C15 21.53 15.08 21.5 15.16 21.48C15.38 21.4 15.61 21.33 15.82 21.24C15.9 21.21 15.98 21.17 16.06 21.13C16.27 21.04 16.48 20.94 16.69 20.83C16.77 20.79 16.84 20.74 16.91 20.7C17.11 20.58 17.31 20.47 17.51 20.34C17.58 20.3 17.64 20.25 17.71 20.2C17.91 20.06 18.1 19.92 18.28 19.77C18.34 19.72 18.39 19.67 18.45 19.63C18.56 19.54 18.67 19.45 18.77 19.36C18.77 19.35 18.77 19.35 18.76 19.34C20.75 17.51 22 14.9 22 12Z'
        fill='white'
      />
      <path
        d='M12 6.93018C9.93 6.93018 8.25 8.61018 8.25 10.6802C8.25 12.7102 9.84 14.3602 11.95 14.4202C11.98 14.4202 12.02 14.4202 12.04 14.4202C12.06 14.4202 12.09 14.4202 12.11 14.4202C12.12 14.4202 12.13 14.4202 12.13 14.4202C14.15 14.3502 15.74 12.7102 15.75 10.6802C15.75 8.61018 14.07 6.93018 12 6.93018Z'
        fill='white'
      />
    </svg>
  )
}

export default Icon
