import { Row } from 'antd'
//components
import GeneralStatisticalItem from './component/general_statistical_item'
//actions
//selector
import { useGeneralSelector } from 'src/store/general/selector'
import { useMemo } from 'react'
//function
//str

const GeneralStatistical = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE ------------------------
  // -------------------------- REDUX ------------------------
  const { statictis, totalOrder } = useGeneralSelector()
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT -----------------------
  const listStatictis = useMemo(() => {
    return [
      {
        title: 'Số lượng giao dịch trong ngày',
        image: '/images/general/amount_transaction.png',
        value: totalOrder,
      },
      {
        title: 'Số lượng người truy cập',
        image: '/images/general/total_ visit.png',
        value: 'https://analytics.google.com/analytics/web',
        isExternalLink: !false
      },
      {
        title: 'Tổng số tiền user quy đổi',
        image: '/images/general/total_amount.png',
        value: statictis?.totalAmount ?? 0,
        tag: "VNDC"
      },
      {
        title: 'Số lượng quy đổi',
        image: '/images/general/total_quanlity.png',
        value: statictis?.totalQuantity ?? 0,
        tag: "Voucher"
      },
      {
        title: 'Số lượng ký quỹ còn lại',
        image: '/images/general/deposit.png',
        value: statictis?.deposit ?? 0,
        tag: "VNDC",
        isShowDanger: !false
      }

    ]
  }, [totalOrder, statictis])
  // -------------------------- RENDER -----------------------
  // -------------------------- MAIN -------------------------
  return (
    <Row justify='space-between' align='middle' gutter={[16, 16]}>
      {listStatictis?.map((dt, index) => {
        return <GeneralStatisticalItem item={dt} key={index} />
      })}
    </Row>
  )
}
export default GeneralStatistical
