import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  typography: {
    fontFamily: `"Readex Pro"`,
  },
  palette: {
    background: {
      default: '#fff',
    },
    warning: {
      main: "#FFF4F0"
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid #DFE1E6`,
            background: "#FFF",
            color:"#091228"
          },
        },
      ],
    },
  },
})