import dayjs from 'dayjs';
import { adminApi } from "src/configs/api/admin"
import { AdminListType } from "./type"



export const getOrderAdminList = async ({skip, limit, fromDate, toDate, search}:{skip: number, limit: number, fromDate: string, toDate: string, search: string}):Promise<AdminListType> => {
    try {
        const res = await adminApi.adminList({skip, limit, fromDate, toDate, search})
        return {
            total: res?.data?.total,
            orders: res?.data?.orders
        }
    } catch (error) {
        console.log(error)
        return {
            total: 0,
            orders: []
        }
    }
}

export const getStatictis = async ():Promise<any> => {
    try {
        const res = await adminApi.statictis()
        return res?.data
    } catch (error) {
        console.log(error)
        return {
            totalAmount: 0,
            totalQuantity: 0,
            deposit: 0
        }
    }
}

export const getTotalOrderByDate = async ():Promise<{totalOrder: number}> => {
    try {
        const fromDate = dayjs().startOf("day").toISOString()
        const toDate = dayjs().toISOString()
        const res = await adminApi.adminList({skip:0, limit:10, fromDate, toDate, search:""})
        return {
            totalOrder: res?.data?.total
        }
    } catch (error) {
        console.log(error)
        return {
            totalOrder: 0,
        }
    }
}
