import { Divider, Form, Input } from 'antd'
//components
//actions
//selector
//type

//function
//str

const FormCreateBrandName = ({ form }: { form: any }) => {
  // -------------------------- VAR ----------------------------
  const brandName = Form.useWatch('brandName', form)

  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <>
      <Form.Item
        label='Tên thương hiệu'
        name='brandName'
        rules={[
          {
            required: true,
            message: 'Tên thương hiệu không được rỗng'
          },
        ]}
        labelCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        wrapperCol={{ xl: 12, lg: 12, md: 12, xs: 24 }}
        labelAlign='left'
      >
        <Input value={brandName} size='large' placeholder='Nhập tên thương hiệu' />
      </Form.Item>
      <Divider />
    </>
  )
}
export default FormCreateBrandName
