import React from "react";
import { AdminInfoItem } from "src/store/auth/type";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from "antd";


export const Logo = ({record}: {record:AdminInfoItem}) => {
    if(record?.logo) {
       return (
            <Avatar size={64} src={record?.logo} />
       )
    }
    return (
        <Avatar size={64} icon={<UserOutlined />} />
    )
}