import React from 'react'

const Icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.86 8.37009L13.93 2.83009C12.86 1.97009 11.13 1.97009 10.07 2.82009L3.14002 8.37009C2.36002 8.99009 1.86002 10.3001 2.03002 11.2801L3.36002 19.2401C3.60002 20.6601 4.96002 21.8101 6.40002 21.8101H17.6C19.03 21.8101 20.4 20.6501 20.64 19.2401L21.97 11.2801C22.13 10.3001 21.63 8.99009 20.86 8.37009ZM12 15.5001C10.62 15.5001 9.50002 14.3801 9.50002 13.0001C9.50002 11.6201 10.62 10.5001 12 10.5001C13.38 10.5001 14.5 11.6201 14.5 13.0001C14.5 14.3801 13.38 15.5001 12 15.5001Z" fill="white"/>
    </svg>

  )
}

export default Icon
