import { Col, Table } from 'antd'
import dayjs from 'dayjs'
import DepositTableAction from './component/deposit_table_action'
import { formatNumer } from 'src/utils/formatNumber'
import { useDepositSelector } from 'src/store/deposit/selector'
import DepositTableFooter from './component/deposit_table_footer'

const DepositTable = () => {
  // ================== VAR =====================
  const columns = [
    {
      title: 'Ngày nhập ký quỹ',
      dataIndex: 'date',
      key: 'date',
      render: (value: string) => {
        return <span>{dayjs(value).format('DD/MM/YYYY HH:mm:ss')}</span>
      }
    },
    {
      title: 'Số tiền ký quỹ (VND)',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: string) => <span>{formatNumer(value)}</span>
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_: any, record: any) => (
        <DepositTableAction amount={record?.amount} date={record?.date} id={record?._id} />
      )
    }
  ]
  // ================== STATE ===================
  // ================== REDUX ===================
  const { urbox, isLoading } = useDepositSelector()
  // ================== FUNCTION ================
  // ================== EFFECT ==================
  // ================== RENDER ==================
  // ================== MAIN ====================
  return (
    <Col span={24} style={{ marginTop: 24 }}>
      <Table
        dataSource={urbox}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        loading={isLoading}
        summary={pageData => {
          let sumAmount = 0
          pageData.forEach(({ amount }) => {
            sumAmount += amount
          })
          if (urbox?.length) {
            return <DepositTableFooter sumAmount={sumAmount} />
          }
          return null
        }}
      />
    </Col>
  )
}

export default DepositTable
