import { createAsyncThunk } from "@reduxjs/toolkit"
import { getOrderAdminList, getStatictis, getTotalOrderByDate } from "./fetchData"
import { AdminListType } from "./type"


export const fetchOrderAdminList = createAsyncThunk<{orderAdminList: AdminListType}, {skip: number, limit: number, fromDate: string, toDate: string, search: string}>(
    'storeGeneral/fetchAllVoucher',
    async ({skip, limit, fromDate, toDate, search}) => {
        const resData = await getOrderAdminList({skip, limit, fromDate, toDate, search})
        return {orderAdminList: resData}
    }
)

export const fetchStatictis = createAsyncThunk<{statictis:{totalAmount: number, totalQuantity: number, deposit: number}}>(
    'storeGeneral/fetchStatictis',
    async () => {
        const resData = await getStatictis()
        return {statictis: {
            totalAmount: resData?.total,
            totalQuantity: resData?.quantity,
            deposit: resData?.deposit
        }}
    }
)


export const fetchTotalOrderByDate = createAsyncThunk<{totalOrder: number}>(
    'storeGeneral/fetchTotalOrderByDate',
    async () => {
        const resData = await getTotalOrderByDate()
        return resData
    }
)