import { createAsyncThunk } from "@reduxjs/toolkit"
import { getUrbox} from "./fetchData"
import { UrboxType } from "./type"


export const fetchUrbox = createAsyncThunk<{urbox: UrboxType[]}>(
    'storeDeposit/fetchUrbox',
    async () => {
        const resData = await getUrbox()
        return resData
    }
)
